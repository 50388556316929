import {
    Button, Alert, Checkbox, Typography,
    Card,
    CardContent,
    
} from '@mui/joy';

import { AppIcon } from '../../components/AppIcon';
import { AppModal } from '../../components/AppModal';
import { PageConfig } from "@app/pages";
import { Form } from "./components/Form";
import { useState } from "react"
import { useAppForm, DataItem } from "@app/system/hooks/useAppForm"
import axios from '../../config/axios.config';
import { MessageAlert } from '@app/components/AppAlert';
import AppAlert from '@app/components/AppAlert';
import { updateRequest } from '@app/api/generic/generic';
import { RenderValue } from '../tables/component/table/RenderValue';
import { useAppSchema } from '@app/system/hooks/useAppSchema';

interface Props {
    open: boolean;
    onClose: () => void;
    pageConfig: PageConfig,
    data: any,
    onSuccess: () => void,
}
export const UpdateForm = ({open, onClose, pageConfig, data, onSuccess }: Props) => {
    const { createFormData, createDataReq, checkFormDataValidation, resetFormError, checkLinkFormDataField } = useAppForm()
    const [formData, setFormData] = useState<DataItem[]>(createFormData(pageConfig.schemas, data))
    const [fromError, setFormError] = useState<MessageAlert|null>(null)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [isNewVersion, setIsNewVersion] = useState<boolean>(false)

    const {getData} = useAppSchema()
    const handleGetData = (ref: string) => {
        const dataValue = getData(data, ref)
        return dataValue
    }

    const updateFormData = (index: number, value: string) => {
        const formDataCopy: DataItem[] = [... formData]
        formDataCopy[index].value = value
        if(value === formDataCopy[index].initValue ){
            formDataCopy[index].edited = false
        }else{
            formDataCopy[index].edited = true
        }   
        const newFormData = checkLinkFormDataField(formDataCopy, 'parrainage')
        setFormData(newFormData);
    }

    const handleClose = () => {
        onClose()
        setFormData(resetFormError(formData))
        setFormError(null)
    } 

    const handleModalSubmit = async () => {
        const {newFormData, isError} = checkFormDataValidation(formData)
        setFormData(newFormData);
        if(isError)return;
        const dataResult: any = createDataReq(formData, true)
        let params = data.id.toString()
        if(pageConfig.config.options.includes('newVersion')){
            params += isNewVersion ? '/true' : ''
        }
        updateRequest(pageConfig.config.apiUrl, params, dataResult, 
            ()=> {
                onClose()
                onSuccess();
            },
            (err) => {
                setFormError({title:'Erreur', text: err , color: 'danger',  })
            }
        )
    }

    const handleSubmitDeleteElement = async () => {
        await axios.delete(pageConfig.config.apiUrl + '/' + data.id).then(() => {
            onClose();
            setOpenDeleteModal(false);
            onSuccess();
        })
    }


    return (
        <>
            <AppModal 
            handleSubmit={handleModalSubmit} 
            icon='PencilIcon' 
            title={`Modifier ${pageConfig.config.itemName}`} 
            submitText="Modifier" 
            submitIcon="PencilIcon"
            open={open} 
            onClose={handleClose} 
            footer={pageConfig.config.options.includes('delete') && <Button variant="soft" color='danger' onClick={() => setOpenDeleteModal(true)} startDecorator={<AppIcon icon='TrashIcon' />}>Archiver</Button>}
            >
                {pageConfig.config.formInfos && 
                    <Card>
                        <CardContent>
                            <div className='text-sm'>
                                {pageConfig.config.formInfos.map((formInfo) => (
                                    <div>
                                    <div>{formInfo.title}</div>
                                    <RenderValue schema={formInfo} field={handleGetData(formInfo.ref)} id={1} onSuccess={() => onSuccess()}/>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                }
                
                <Form updateFormData={updateFormData} formData={formData}></Form>
                {/* new version */}
                {pageConfig.config.options.includes('createNewVersion') &&  
                    <div className='flex w-full justify-end items-center space-x-2 mt-4'>
                        <Checkbox checked={isNewVersion} onClick={() => setIsNewVersion(!isNewVersion)} />
                        <Typography fontSize='sm' >Crée une nouvelle version</Typography> 
                    </div>
                }
                 
                {fromError && <div className="mt-4"><AppAlert message={fromError} /></div>}
            </AppModal>

            <AppModal title='Archiver'  open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} handleSubmit={handleSubmitDeleteElement} >
                <Alert startDecorator={<AppIcon icon='ShieldExclamationIcon' />} variant="soft" color="warning">Êtes-vous sûrs de vouloir archiver cet élément ?</Alert>
            </AppModal>
        </>
    )
}
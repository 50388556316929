export function capitalizeFirstLetter(value: string): string {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function camelCaseToText(value: string): string {
  const result = value.replace(/([A-Z])/g, " $1").trim();
  return capitalizeFirstLetter(result)
} 

export function displayMoneyAmount(value: string){
  var parts = value.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let endPart: string; 
    if(parts[1]){
      if(parts[1].length === 1){
        endPart = parts[1] + '0'
      }else{
        endPart = parts[1]
      }
    }else{
      endPart = '00'
    }
    return parts[0] + '.' + endPart
}

export function splitArray(array: any[], numParts: number ): any[][] {
  if (numParts < 1 || numParts > array.length) {
    return [];
  }

  const chunkSize = Math.ceil(array.length / numParts);
  const result = [];

  for (let i = 0; i < numParts; i++) {
    const start = i * chunkSize;
    const end = start + chunkSize;
    result.push(array.slice(start, end));
  }

  return result;
}

export const returnErrorMsg = (err: any): string => {
  if(err.response.data.message){
    if(typeof err.response.data.message === 'string'){
      return err.response.data.message
    }else{
      return err.response.data.message.join(", ")
    }
  }else{
    return err.response.data.error
  }
}

import { PageConfig } from '../type';

export const commandeSchema: PageConfig = {
  config: {
    modelName: 'Commandes Materiels',
    itemName: 'une commande',
    apiUrl: '/commandes',
    options: [
      'create',
      'update',
      'updateMany',
      'delete',
      'filter',
      'order',
      'deleteMany',
    ],
  },
  schemas: [
    {
      style: { width: 100 },
      ref: 'ref',
      type: 'ref',
      search: true,
      form: { display: false },
    },
    {
      style: { width: 120 },
      ref: 'mandat/ref',
      type: 'ref',
      form: { display: false },
    },
    {
      style: { width: 160 },
      ref: 'mandat/club/nom',
      type: 'ref',
      form: { display: false },
    },
    { style: { width: 200 }, ref: 'refInterneMv', type: 'ref' },
    { style: { width: 200 }, ref: 'refCommandeClub', type: 'ref' },
    { style: { width: 200 }, ref: 'refCommandeFournisseur', type: 'ref' },
    {
      style: { width: 240 },
      ref: 'marque/nom',
      search: true,
      form: { display: false },
    },
    {
      style: { width: 200 },
      ref: 'article',
      search: true,
      form: { requier: true },
    },
    {
      style: { width: 100 },
      ref: 'quantite',
      type: 'amount',
      form: { requier: true },
    },
    { style: { width: 140 }, ref: 'couleur' },
    { style: { width: 140 }, ref: 'notes' },
    {
      style: { width: 100 },
      ref: 'coutBrut',
      type: 'money',
      form: { requier: true },
    },
    {
      style: { width: 100 },
      ref: 'coutNet',
      type: 'money',
      form: { requier: true },
    },
    {
      style: { width: 140 },
      ref: 'dateCommande',
      type: 'date',
      form: { requier: true },
    },
    { style: { width: 140 }, ref: 'destinataire' },
    {
      style: { width: 140 },
      ref: 'fournisseur/nom',
      type: 'custom',
      ban: ['filter', 'order'],
      form: { display: false },
    },
    { style: { width: 140 }, ref: 'datePaiement', type: 'date' },
    {
      style: { width: 340 },
      ref: 'dateCommandeAnnuleeOuNonFacturee',
      type: 'date',
    },

    // only for form
    {
      style: { width: 240, isDisplayInTable: false },
      ref: 'marque/nom',
      title: 'Marque',
      form: {
        requier: true,
      },
    },
    {
      style: { width: 140, isDisplayInTable: false },
      ref: 'fournisseurId',
      form: {
        requier: true,
        select: {
          apiUrl: '/fournisseurs',
          fieldName: 'nom',
          fieldResult: 'id',
          type: 'select',
        },
      },
    },
    {
      style: { width: 140, isDisplayInTable: false },
      ref: 'mandatId',
      form: {
        requier: false,
        select: {
          apiUrl: '/mandats',
          fieldName: 'ref',
          fieldResult: 'id',
          type: 'select',
        },
      },
    },
  ],
};

import { StatusChip } from "./StatusChip"
import { Schema } from "@app/pages";
import { useAppNavigation } from '@app/system/hooks/useAppNavigation'
import { camelCaseToText } from "@app/system/utils/basic";


interface Props {
    field: []
    schema: Schema
    id: number
}

export const RelationBtn = ({ field, schema, id }: Props) => {
    const { navigateToRelation } = useAppNavigation()
    if (!schema.redirect || !schema.redirect.itemName || !schema.redirect.to) {
        throw new Error("yout need the key relation")
    }
    const number = field.length

    const handleClick = () => {
        schema.redirect && navigateToRelation(schema.redirect.to, id, schema.redirect.from)
    }

    return (
        <StatusChip color={number > 0 ? 'blue' : 'green'} title={number + ' ' + schema.redirect.itemName} icon='ChevronRightIcon' iconEnd handleClick={handleClick} />
    )
} 
import { PageConfig } from '../type';

export const materialDueToClubViewSchema: PageConfig = {
  config: {
    modelName: 'Matériel dû au club',
    itemName: 'un materiel du au club',
    apiUrl: '/views/materialDueToClub',
    options: ['order', 'filter'],
  },
  schemas: [
    {
      style: { width: 140, isDisplayInTable: false },
      ref: 'dateAccord',
      title: "Date d'accord",
      type: 'date',
    },
    {
      style: { width: 100 },
      ref: 'ref',
      title: 'Mandat Id',
      type: 'ref',
      search: true,
    },
    {
      style: { width: 120 },
      ref: 'club/nom',
      title: 'Nom du club',
      search: true,
    },
    { style: { width: 100 }, ref: 'budget', type: 'money', search: true },
    {
      style: { width: 160 },
      ref: 'MontantPromesses',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 200 },
      ref: 'MontantParrainagesPayes',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 200 },
      ref: 'MontantInitialDisponible',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 240 },
      ref: 'MontantMaterielInitialDisponible',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 200 },
      ref: 'MontantDisponibleRestant',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 200 },
      ref: 'TotalRistournesVersees',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 180 },
      ref: 'MaterielReçuCoutBrut',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 180 },
      ref: 'MaterielReçuCoutNet',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 200 },
      ref: 'MontantRistourneRestant',
      type: 'money',
      ban: ['order', 'filter'],
    },
    {
      style: { width: 200 },
      ref: 'MontantMaterielRestant',
      type: 'money',
      ban: ['order', 'filter'],
    },
    { style: { width: 140 }, ref: 'dateCloture', type: 'date' },
    { style: { width: 140 }, ref: 'cloture', type: 'bool' },
  ],
};

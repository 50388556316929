import { PageConfig } from '../type';

export const reconductionsPourAssistantPotentielSchema: PageConfig = {
  config: {
    modelName: 'reconductions Pour Assistant Potentiel',
    itemName: 'une reconductions',
    apiUrl: '/views/reconductionPourAssistantPotentiel',
    options: ['filter', 'order'],
    banExport: ['pdf'],
  },

  schemas: [
    {
      style: { width: 200 },
      ref: 'personneMorale/raisonSociale',
      title: 'Raison Sociale',
      type: 'HTMLText',
      search: true,
    },
    {
      style: { width: 140 },
      ref: 'personneMorale/adresse/ligne1',
      title: 'Adresse',
    },
    { style: { width: 140 }, ref: 'personneMorale/adresse/NPA', title: 'Npa' },
    {
      style: { width: 140 },
      ref: 'personneMorale/adresse/region',
      title: 'Localite',
    },
    { style: { width: 140 }, ref: 'telephone1', ban: ['order', 'filter'] },
    { style: { width: 140 }, ref: 'telephone2', ban: ['order', 'filter'] },
    { style: { width: 140 }, ref: 'dateAccord', type: 'date' },
    { style: { width: 140 }, ref: 'mandat/ref', title: 'Id Club' },
  ],
};

export default reconductionsPourAssistantPotentielSchema;

import { IconName } from "./AppIcon";
import { AppModal } from "./AppModal" 
import { useState } from 'react';
import { MessageAlert } from "./AppAlert";
import AppDropZone from "./AppDropZone";

interface prop{
    icon: IconName;
    onSubmit: (arg1: File) => any;
    open: boolean,
    onClose: () => void,
    loading: boolean;
    message: MessageAlert|null;
    acceptFile: string
}

const AppDropZoneModal = ({ icon, onSubmit, open, onClose, loading, message = null, acceptFile}: prop) => {
    const [file, setFile] = useState<File|null>()

    const handleFileUpload = (file: File) => {
        setFile(file);
    };

    const handleUploadClick = () => {
        if (!file) {
            return;
        }
        onSubmit(file)
        setFile(null)
    };


  return (
    <AppModal closeOnSubmit={false} disabelSubmit={!file} open={open} onClose={onClose} handleSubmit={handleUploadClick} submitText='Importer' icon={icon} title={'Import'} >
        <AppDropZone loading={loading} message={message} acceptFile={acceptFile} file={file || null} onFileUpload={handleFileUpload} />
    </AppModal>
  );
}

export default AppDropZoneModal;
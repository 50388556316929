import SubRelationForm from "./components/SubRelationForm"

import { AppModal } from "@app/components/AppModal"
import { PageConfig } from "@app/pages/type";

type RelationsFormProps = {
    pageConfig: PageConfig;
    data: any;
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const RelationsForm = ({pageConfig, data, open, onClose, onSuccess}: RelationsFormProps) => {
    const success = () => {
        onSuccess()
        onClose()
    }
    return <AppModal open={open} onClose={onClose} title="Relation">
        <SubRelationForm pageConfig={pageConfig} data={data} onSuccess={success}/>
    </AppModal>
}

export default RelationsForm
import { PageConfig } from '../type';

export const adresseSchema: PageConfig = {
  config: {
    modelName: 'adresses',
    itemName: 'une adresse',
    apiUrl: '/adresses',
    options: ['create', 'update', 'updateMany', 'delete', 'filter', 'order'],
  },
  schemas: [
    { ref: 'Ligne 1', title: 'Adresse' },
    { ref: 'ville' },
    { ref: 'casePostal' },
    // {ref:"NPA"},
    { ref: 'region' },
    // {ref:"pays"},
  ],
};

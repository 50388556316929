import axios from '../../config/axios.config';
import { getTablesParams } from '../apiType';
import { returnErrorMsg } from '@app/system/utils/basic';

export const getAllRequest = async (
  route: string,
  params: getTablesParams,
  onSucces?: () => void,
  onError?: (arg: string) => void,
): Promise<{
  result: any[];
  totalCount: number;
  aggregate: any;
  more: any;
}> => {
  return await axios
    .get(route, { params })
    .then((res) => {
      onSucces && onSucces();
      return res.data;
    })
    .catch((err) => {
      onError && onError(returnErrorMsg(err));
      return { result: [], totalCount: 0, aggregate: null, more: null };
    });
};

export const getIdRequest = async (
  route: string,
  id: number | string,
  onSucces?: () => void,
  onError?: (arg: string) => void,
): Promise<any> => {
  return await axios
    .get(route + '/' + id)
    .then((res) => {
      onSucces && onSucces();
      return res.data;
    })
    .catch((err) => {
      onError && onError(returnErrorMsg(err));
    });
};

export const updateRequest = async (
  route: string,
  id: string,
  data: any,
  onSucces?: () => void,
  onError?: (arg: string) => void,
) => {
  return await axios
    .patch(route + '/' + id, data)
    .then((res) => {
      onSucces && onSucces();
      return res.data;
    })
    .catch((err) => {
      onError && onError(returnErrorMsg(err));
    });
};

export const createRequest = async (
  route: string,
  data: any,
  onSucces?: () => void,
  onError?: (arg: string) => void,
): Promise<void> => {
  return await axios
    .post(route, data)
    .then((res) => {
      onSucces && onSucces();
      return res.data;
    })
    .catch((err) => {
      onError && onError(returnErrorMsg(err));
    });
};

export const deleteRequest = async (
  route: string,
  id: string,
  onSucces?: () => void,
  onError?: (arg: string) => void,
): Promise<void> => {
  return await axios
    .delete(route + '/' + id)
    .then((res) => {
      onSucces && onSucces();
      return res.data;
    })
    .catch((err) => {
      onError && onError(returnErrorMsg(err));
    });
};

import * as React from 'react';
import { FormControl, FormLabel, Input, Select, Option, ListItemDecorator } from '@mui/joy';
import { AppIcon } from '@app/components/AppIcon';
import { useAppNavigation } from '@app/system/hooks/useAppNavigation';
import { Schema, PageConfig } from '@app/pages';
import { SelectItem, useAppSchema } from '@app/system/hooks/useAppSchema';



interface props {
    pageConfig: PageConfig
}
export const OrderAndSearch = ({ pageConfig }: props) => {
    const {transformSchemaTypeToApiFilters, renderFilterIcon, getSearchString, getSelectItemList } = useAppSchema()
    const { setParams, getParams } = useAppNavigation()

    const modelName = pageConfig.config.modelName
    const searchField = getSearchString(pageConfig.schemas)

    const handleChangeSort = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
    ) => {
        if (newValue !== null) {
            if (getParams('sort_by') === '') {
                setParams('asc', "");
            }
            if(getParams('asc') === ''){
                setParams('asc', 'true');
            }
            setParams('sort_by', newValue)
        }
    };

    const handleChangeAsc = (
        event: React.SyntheticEvent | null,
        newValue: boolean | null,
    ) => {
        if (newValue !== null) {
            setParams('asc', newValue.toString())
        }
    };
    
    React.useEffect(() => {
        const filterRef = getParams('filter_by');
        if(filterRef){
            const schemaFind = pageConfig.schemas.find(schema => schema.ref === filterRef && schema.type !== 'subSchema' )
            if(schemaFind && schemaFind.type){
                schemaFind
                setParams('filter_type', transformSchemaTypeToApiFilters(schemaFind.type));
            }
        }
        
    }, [getParams('filter_by')])

    const delayDebounceFn = React.useRef<NodeJS.Timeout | null>(null);

    const handleChangeText = (value: string, secondary: boolean = false) => {
        if (delayDebounceFn.current) {
            clearTimeout(delayDebounceFn.current);
        }

        delayDebounceFn.current = setTimeout(() => {
            setParams(secondary ? 'secondarySearch' : 'search', value.trim());
            setParams('page', '1');
        }, 500);
    };

    return (
        <>
            <FormControl sx={{ flex: 1 }} size="sm">
                <FormLabel>Recherche dans {modelName}</FormLabel>
                <Input
                    onChange={(e) => handleChangeText(e.target.value)}
                    defaultValue={getParams('search')} 
                    size="sm"
                    placeholder={searchField}
                    startDecorator={<AppIcon icon='MagnifyingGlassIcon' />}
                />
            </FormControl>

            {pageConfig.config.secondarySearch && 
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Recherche {pageConfig.config.secondarySearch}</FormLabel>
                    <Input
                        onChange={(e) => handleChangeText(e.target.value, true)}
                        defaultValue={getParams('secondarySearch')} 
                        size="sm"
                        placeholder={pageConfig.config.secondarySearch}
                        startDecorator={<AppIcon icon='MagnifyingGlassIcon' />}
                    />
                </FormControl>
            }

            {pageConfig.config.options.includes('order') && 
            <> 
                <FormControl size="sm">
                    <FormLabel>Ordonner par</FormLabel>
                    <Select
                        size="sm"
                        placeholder="---"
                        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                        value={getParams('sort_by')}
                        onChange={handleChangeSort}
                    >
                        <Option value=''>---</Option>
                        <Option value='createdAt'>
                            {renderFilterIcon('date')}
                            Date de création
                        </Option>

                        {getSelectItemList(pageConfig.schemas, false).map((selectItem: SelectItem, index) => (
                            <Option key={index} value={selectItem.ref}>
                                {renderFilterIcon(selectItem.type)}
                                {selectItem.title}
                            </Option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size="sm">
                    <FormLabel>Ordre</FormLabel>
                    <Select
                        size="sm"
                        disabled={getParams('sort_by') === ''}
                        placeholder="---"
                        value={getParams('asc') === 'true' ? true : getParams('asc') === 'false' ? false : null}
                        onChange={handleChangeAsc}
                    >
                        <Option disabled value=''>---</Option>
                        {getParams('sort_by') !== '' && 
                            <>
                                <Option value={true}>
                                    <ListItemDecorator>
                                        <AppIcon icon="BarsArrowUpIcon" />
                                    </ListItemDecorator>
                                    Croissant
                                </Option>
                                <Option value={false}>
                                    <ListItemDecorator>
                                        <AppIcon icon="BarsArrowDownIcon" />
                                    </ListItemDecorator>
                                    Décroissant
                                </Option>
                            </>
                        }   
                        
                    </Select>
                </FormControl> 
            </>
            } 
               
        </>
    )

};

export default OrderAndSearch;

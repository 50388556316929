// view
import {
  parrainageViewSchema,
  factureViewSchema,
  parrainagesCurrentMonthSchema,
  materialDueToClubViewSchema,
  Schema,
  PageConfig,
} from '@app/pages';

// data
import {
  clubSchema,
  commandeSchema,
  factureSchema,
  fournisseurSchema,
  parrainageSchema,
  mandatSchema,
  personneMoraleSchema,
  personnePhysiqueSchema,
  userSchema,
} from '@app/pages';

const urlToSchemaDATA: any = {
  clubs: clubSchema,
  commandes: commandeSchema,
  factures: factureSchema,
  fournisseurs: fournisseurSchema,
  parrainages: parrainageSchema,
  mandats: mandatSchema,
  personneMorales: personneMoraleSchema,
  personnePhysiques: personnePhysiqueSchema,
  collaborateurs: userSchema,
};

const urlToSchemaVIEW: any = {
  parrainages: parrainageViewSchema,
  factures: factureViewSchema,
  parrainagesCurrentMonth: parrainagesCurrentMonthSchema,
  materialDueToClub: materialDueToClubViewSchema,
};

export const getPageConfig = (baseApp: string, schemaName: string) => {
  if (baseApp === 'data') {
    return urlToSchemaDATA[schemaName];
  }
  if (baseApp === 'view') {
    return urlToSchemaVIEW[schemaName];
  } else {
    throw new Error('baseApp is missing');
  }
};

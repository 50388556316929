import { Schema } from "@app/pages"
import { useEffect, useState } from "react"
import RenderField from "./RenderField"
import { splitArray } from "@app/system/utils/basic"
import { useAppForm, DataItem } from "@app/system/hooks/useAppForm"


interface Props {
    updateFormData: (index: number, value: string) => void
    formData: DataItem[]
}

export const Form = ({ formData, updateFormData }: Props) => {

    
    let realIndex = 0
    const updateIndex = (): string => {
        realIndex += 1
        return ''
    }

    const splitList = {
        3: 1,
        10: 2,
        20 : 3,
        35: 4,
        40: 5,
    } 

    const getSpliteArrar = (): number => {
        const listFind = Object.entries(splitList).find( list => parseInt(list[0]) >= formData.length)
        return listFind ? listFind[1] : 5 
    }

    return (
        <div className="flex space-x-12 px-12">
            {splitArray(formData, getSpliteArrar()).map((list, index) => (
                <div key={index}>
                    {list.map((dataItem) => (
                        
                        <div key={realIndex}>
                            
                            <RenderField 
                                dataItem={dataItem} 
                                updateValue={(index: number, value: string) => updateFormData(index, value)}
                                index={realIndex}
                            />
                            {updateIndex()}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
} 
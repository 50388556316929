
import { AppModal } from '../../components/AppModal';
import { PageConfig } from "@app/pages";
import {useState } from "react"

import { MessageAlert } from '@app/components/AppAlert';
import AppAlert from '@app/components/AppAlert';

interface Props {
    open: boolean;
    onClose: () => void;
    pageConfig: PageConfig,
    onSuccess: () => void,
    data: any,
}
export const AddForm = ({open, onClose, pageConfig, onSuccess, data }: Props) => {
    const [fromError, setFormError] = useState<MessageAlert|null>(null)

    
    const handleClose = () => {
        onClose()
        setFormError(null)
    }

    const handleModalSubmit = async () => {
    }
    return (
        <>
            <AppModal 
            handleSubmit={handleModalSubmit} 
            icon='PencilIcon' 
            title={`Modifier ${pageConfig.config.itemName}`} 
            submitText="Modifier" 
            submitIcon='PencilIcon'
            open={open} 
            onClose={handleClose} 
            >
                {fromError && <div className="mt-4"><AppAlert message={fromError} /></div>}
                
            </AppModal>
        </>
    )
}
import { PageConfig } from '../type';

export const userSchema: PageConfig = {
  config: {
    modelName: 'Collaborateurs',
    itemName: 'un collaborateur',
    apiUrl: '/users',
    options: ['create', 'update', 'updateMany', 'delete', 'filter', 'order'],
  },
  schemas: [
    {
      style: { width: 140 },
      ref: 'ref',
      type: 'ref',
      ban: ['create', 'update'],
    },
    {
      style: { width: 140 },
      ref: 'prenom',
      search: true,
      form: { requier: true },
    },
    {
      style: { width: 140 },
      ref: 'nom',
      search: true,
      form: { requier: true },
    },
    { style: { width: 140 }, ref: 'email' },
    {
      style: { width: 0, isDisplayInTable: false },
      ref: 'password',
      ban: ['update'],
      form: { requier: true },
    },
    {
      style: { width: 140 },
      ref: 'statut',

      type: 'select',
      form: { requier: true },
      valueMap: [
        { value: 'interne', color: 'blue', icon: 'ArrowLeftOnRectangleIcon' },
        { value: 'externe', color: 'red', icon: 'ArrowRightOnRectangleIcon' },
      ],
    },
    {
      style: { width: 140 },
      ref: 'dateEntree',
      title: "Date d'entrée",
      type: 'date',
      form: { requier: true },
    },
    {
      style: { width: 140 },
      ref: 'dateSortie',
      title: 'Date de sortie',
      type: 'date',
      ban: ['create'],
    },
    { style: { width: 140 }, ref: 'notes' },
  ],
};

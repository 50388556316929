import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import AppLoading from '../../components/AppLoading';

const HomeView = lazy(() => import('./HomeView'));

export default [
  <Route
    path="/home"
    element={
      <Suspense fallback={<AppLoading />}>
        <HomeView />
      </Suspense>
    }
  />
]




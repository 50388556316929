import * as SolidIcons from '@heroicons/react/24/solid';
import * as OutlineIcons from '@heroicons/react/24/outline';

export type IconName = keyof typeof SolidIcons | keyof typeof OutlineIcons;

interface Props {
    icon: IconName;
    solid?: boolean;
    size?: 'sm' | 'md' | 'lg'
}

export const AppIcon = (props: Props): JSX.Element => {
    const { icon, solid = false, size } = props;

    const Icon = solid ? SolidIcons[icon] : OutlineIcons[icon];

    return <Icon className={`w-[20px] h-[20px] text-gray-600`} />;
};
import axios from "../../../config/axios.config";
import { AppModal } from "@app/components/AppModal";
import AppAlert from "@app/components/AppAlert";

type props = {
    onSuccess: () => void,
    idToUpdate: number[],
    open: boolean,
    onClose: () => void,
} 

export const ValidateListeNoire = ({ idToUpdate, onSuccess, open, onClose }: props) => {
    // Liste Noire Affecté à
    
    const submit = () => {
        idToUpdate.forEach(id => {
            axios.patch(`/liste-noire/${id}/validate`).then(resp => {
                onSuccess();
                onClose();
            })
        })
    }
    
    return (
        <AppModal 
            handleSubmit={submit} 
            icon='PencilSquareIcon' 
            title={`Validation liste noire`} 
            submitText="Valider" 
            submitIcon="PencilSquareIcon"
            open={open} 
            onClose={onClose} 
            >
            <AppAlert message={{
                color: 'warning',
                title: "Validation de la liste noire",
                text: "Voulez-vous valider la liste noire ?"
            }} />
        </AppModal>
    )
}
export default ValidateListeNoire;



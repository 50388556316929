
import { StatusChip } from "./StatusChip"
import { PageConfig } from "@app/pages";
import AppDrawer from "@app/components/AppDrawer";
import { useEffect, useState } from "react";
import { OrderTable } from "./OrderTable";
import { TableHeaderLayout } from "../header/TableHeaderLayout";
import { getIdRequest } from "@app/api/generic/generic";
import { set } from "date-fns";

interface Props {
    modelId: string
    pageConfig: PageConfig
    open: boolean
    onClose: () => void
}


const VersionHistory = ({modelId, pageConfig, open, onClose}: Props) => {

    const [rawData, setRawData] = useState<string>('')

    const [previousVersionsData, setPreviousVersionsData] = useState<any[]>([])
    const [newVersionsData, setNewVersionsData] = useState<any[]>([])

    const getVersionsHistory = async () => {
        const res = await getIdRequest(pageConfig.config.apiUrl + '/VersionHistory', modelId)
        console.info(res)
        setPreviousVersionsData(res.previousVersions)
        setNewVersionsData(res.newVersions)
        setRawData(JSON.stringify(res))
    }

    useEffect(() => {
        getVersionsHistory()
    }, [])
    return (
        <div>
           <AppDrawer onClose={onClose} title="test" open={open}>
                <>data:</>
                {rawData}
                {/* <TableHeaderLayout>
                    {previousVersionsData.length > 0 &&
                        <OrderTable 
                            onSuccess={() => console.info('success')}
                            pageConfig={pageConfig}
                            data={previousVersionsData}
                            isLoaded />
                    }
                    {newVersionsData.length > 0 &&
                        <OrderTable 
                            onSuccess={() => console.info('success')}
                            pageConfig={pageConfig}
                            data={newVersionsData}
                            isLoaded />
                    }
                    
                </TableHeaderLayout> */}
            </AppDrawer>
        </div>
    );
};

export default VersionHistory;
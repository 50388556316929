import * as React from 'react';
import { Transition } from 'react-transition-group';
import { Box, Button, Divider, Modal, ModalDialog, Typography, IconButton } from '@mui/joy';


import { AppIcon, IconName } from './AppIcon';
import { ReactNode } from "react";

interface Props {
  open: boolean,
  // setOpen: (open: boolean) => void,
  onClose: () => void,
  icon?: IconName,
  title: string,
  subTitle?: string,
  children: ReactNode,
  submitText?: string,
  submitIcon?: IconName,
  handleSubmit?: Function,
  disabelSubmit?: boolean,
  closeOnSubmit?: boolean,
  footer?: ReactNode,

}

export function AppModal({ open, onClose, title, subTitle = '', icon, children, submitText = "Valider", submitIcon, handleSubmit, disabelSubmit = false, closeOnSubmit = true, footer }: Props) {
  return (
      <Transition in={open} timeout={400}>
        {(state: string) => (
          <Modal
            keepMounted
            open={!['exited', 'exiting'].includes(state)}
            onClose={onClose}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: 'none',
                  transition: `opacity 200ms, backdrop-filter 200ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: 'blur(4px)' },
                    entered: { opacity: 1, backdropFilter: 'blur(4px)' },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === 'exited' ? 'hidden' : 'visible',
            }}
          >
            <ModalDialog
              variant="outlined"
              role="alertdialog"
              aria-labelledby="alert-dialog-modal-title"
              aria-describedby="alert-dialog-modal-description"
              sx={{
                opacity: 0,
                transition: `opacity 200ms, scale 200ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <div className='flex justify-between items-center'>
                <div className="flex justify-center items-center">
                  {icon && <div className='px-1'><AppIcon icon={icon} /></div>}
                  <Typography
                    id="alert-dialog-modal-title"
                    
                  >
                    {title}
                  </Typography>
                </div>
                <div className="-mt-6 -mr-4">
                  <IconButton variant='outlined' sx={{borderRadius: '50%', scale: '0.8'}} onClick={onClose}>
                    <AppIcon icon="XMarkIcon" />
                  </IconButton>
                </div>
              </div>

              <Divider />
              <Box sx={{
                      overflowY: 'auto',
                      mx: 'calc(-1 * var(--ModalDialog-padding))',
                      px: '25px',
                      py: '25px'
                    }}
              >
                {children}
              </Box>
              <Divider />
              <Box className="flex justify-between items-center" sx={{ gap: 1, pt: 2 }}>
                <div>
                  {footer}
                </div>    
                <div className="flex space-x-2">    
                  <Button variant="plain" color="neutral" startDecorator={<></>} onClick={onClose}>
                    Fermer
                  </Button>
                  {handleSubmit &&
                  <Button disabled={disabelSubmit} startDecorator={submitIcon && <><AppIcon icon={submitIcon} /></>} variant="soft" color="primary" onClick={() => { handleSubmit(); closeOnSubmit && onClose }}>
                    {submitText}
                  </Button>}
                </div>
              </Box>
            </ModalDialog>

          </Modal>
        )}
      </Transition>
  );
}

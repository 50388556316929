import { StatusChip } from "./StatusChip"
import { useState, useRef } from "react";
import Main from "../../Main";
import { getPageConfig } from "@app/pages/utils";
import { Box } from "@mui/joy";
import { PageConfig } from "@app/pages";

interface Props {
    idList: any[]
    pageConfig: PageConfig
    title: string
    amount: number
}

export const SubFullTableBtn = ({ idList, pageConfig, title, amount }: Props) => {
    const [open, setOpen] = useState(false)
    const loadPageConfig = getPageConfig('data' , pageConfig.config.apiUrl.replace('/', ''))
    return (
        <>
            <div className="relative" >

                <StatusChip
                    color={amount === 0 ? 'green' : 'blue'}
                    title={title}
                    selected={open}
                    icon={open ? 'ChevronDownIcon' : 'ChevronUpIcon'}
                    iconEnd
                    handleClick={() => setOpen(!open)}
                />

                {open &&
                    <Box
                        className='absolute z-99 shadow-md mt-2'
                        style={{ "boxShadow": "5px 5px 15px -1px rgba(0,0,0,0.3)" }}
                        sx={{
                            borderRadius: 'sm',
                            display: {
                                xs: 'none',
                                sm: 'flex',
                            },
                            flexWrap: 'wrap',
                            gap: 1.5,

                        }}
                    >
                        <Main pageConfig={loadPageConfig} />
                    </Box>
                }

            </div>
        </>
    )
} 
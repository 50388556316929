import { useState, useEffect } from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import { getAllRequest } from '@app/api/generic/generic';
import { getTablesParams } from '@app/api/apiType';
import axios from '@app/config/axios.config';

interface props {
    apiUrl: string;
    keyDisplay: string;
    dbSendKey?: string;
    onChange: (arg1: number | undefined | string) => void;
    initialId: number | null;
}

const SearchAutocomplete = ({
    apiUrl,
    keyDisplay,
    dbSendKey,
    onChange,
    initialId,
}: props) => {
    type OptionData = { id: number | null; label: string; value?: string };
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<OptionData[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionData | null>(
        null
    );
    const [initialOption, setInitialOption] = useState<OptionData>({
        id: null,
        label: '',
        value: '',
    });

    useEffect(() => {
        if (initialId) {
            axios.get(`${apiUrl}/${initialId}`).then((res) => {
                console.log('res:', res);
                console.log('keyDisplay:', res.data[keyDisplay]);
                setInitialOption({
                    id: initialId,
                    label: res.data[keyDisplay],
                });
            });
        }
    }, [initialId]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (inputValue) {
                setLoading(true);
                const param: getTablesParams = {
                    page: 0,
                    limit: 5,
                    search: inputValue,
                    secondarySearch: '',
                    sort_by: '',
                    asc: false,
                    filters: [],
                };
                const result = (await getAllRequest(apiUrl, param)).result;
                setOptions(
                    result.map((item: any) => {
                        return {
                            id: item.id,
                            label: item[keyDisplay],
                            value: dbSendKey ? item[dbSendKey] : '',
                        };
                    })
                );
                setLoading(false);
            } else {
                setOptions([]);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [inputValue]);

    const handleChange = (newValue: OptionData | null) => {
        console.log('newValue:', newValue);
        if (newValue !== null) {
            setSelectedOption(newValue);
            newValue.id && onChange(dbSendKey ? newValue.value : newValue.id);
        } else {
            setSelectedOption({ id: null, label: '', value: '' });
            onChange(undefined);
        }
    };

    return (
        <div>
            <Autocomplete
                size="sm"
                options={options}
                loading={loading}
                placeholder="Rechercher..."
                defaultValue={initialOption}
                value={selectedOption ? selectedOption : initialOption}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                    handleChange(newValue);
                }}
                onInputChange={(event, newValue) => {
                    setInputValue(newValue);
                }}
            />
        </div>
    );
};

export default SearchAutocomplete;

export const stringToHtml = (encodedString: string) => {
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = encodedString;
    var decodedString = '';
    for (var i = 0; i < tempDiv.childNodes.length; i++) {
    decodedString += tempDiv.childNodes[i].nodeValue || tempDiv.childNodes[i].textContent;
    }
    return decodedString;
}

export const decodeHTMLEntities = (input: string) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = input;
    return textarea.value;
  }
import { PageConfig } from "@app/pages"
import FilterDateMonth from "./Custom/FilterDateMonth";
import CustomCommandeClub from "./Custom/CustomCommandeClub";
import FilterCollaborateur from "./Custom/FilterCollaborateur";
import FilterUserLogin from "./Custom/FilterUserLogin";


type props = {
    pageConfig: PageConfig 
    onSuccess: () => void;
}
const CustomAction = ({pageConfig, onSuccess}: props) => {
    
    return (
        <>
            {pageConfig.config.apiUrl === '/commandes' && 
                <CustomCommandeClub onSuccess={onSuccess} />
            }
            {pageConfig.config.apiUrl === '/view-parrainages/currentMonth' && 
                <>
                    <FilterDateMonth filterBy='dateAccord' />
                    <FilterCollaborateur filterBy='collaborateur/id' />
                </>
            }
            {pageConfig.config.indentifier === 'listeNoireUserAdminSchema' && 
                <>
                    <FilterDateMonth filterBy='dateListeNoire' />
                    <FilterUserLogin filterBy='collaborateur' />
                </>
            }
        </>
    
    )

}

export default CustomAction
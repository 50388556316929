import { Schema } from "@app/pages"
import { Fragment } from "react"
type props = {
    data: any,
    schema: Schema,
}
export default function RenderCustomValue({ data, schema }: props) {
    if(schema.ref === 'customFacturesPersonnePhysique'){
        data = data.parrainageAccordDe.concat(data.parrainagePersonnePhysique)
        return <>{data.map((item: any, index: number) => (<Fragment key={index}>
            <div>{item.facture.numeroFacture}</div> 
        </Fragment>))}</>
    }else if(schema.ref === 'customFacturePersonneMoral'){
        return <>{data.parrainagePersonneMorale.map((item: any, index: number) => (<Fragment key={index}>
            <div>{item.facture?.numeroFacture}</div> 
        </Fragment>))}</>
    }else if(schema.ref === 'fournisseur/nom'){
        return <>{data.fournisseur.map((item: any, index: number) => (<Fragment key={index}>
            <div>{item.nom}</div> 
        </Fragment>))}</>
    }
    

}
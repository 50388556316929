import { CircularProgress } from "@mui/joy";

interface props{
  center?: boolean;
}

const AppLoading = ({center = true}: props) => {
  return (
    <div className={center ? "flex  items-center justify-center" : ''}>
       <CircularProgress 
          color="neutral"
          sx={{
            "--CircularProgress-progressThickness": "2px",
            "--CircularProgress-trackThickness": "2px",
            "--CircularProgress-size": "22px"
          }}
        />
    </div>
  );
};

export default AppLoading;
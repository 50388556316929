import { useEffect, useState } from 'react';
import Pagination from './component/pagination/Pagination';
import Table from './component/table/OrderTable';
import OrderAndSearch from './component/header/OrderAndSearch';
import { TableHeaderLayout } from './component/header/TableHeaderLayout';
import Options from './component/header/Option';
import { useAppNavigation } from '@app/system/hooks/useAppNavigation';
import { useLocation } from 'react-router-dom';
import AppDropZoneModal from '@app/components/AppDropZoneModal';
import { ModelTarget, migrateCsv, migrateXml } from '@app/api/migrations/migration';
import { MessageAlert } from '@app/components/AppAlert';
import { Filters } from './component/header/Filters';
import { CreateForm } from '@app/main/forms/CreateForm';
import { PageConfig } from '@app/pages';
import { getAllRequest } from '@app/api/generic/generic';
import { ItemOption } from './component/header/Option'
import CustomAction from './component/header/CustomAction';
import { exportExcelFile } from '@app/system/utils/dataExporter';

interface Props {
  pageConfig: PageConfig
}

type ImportType = 'xml' | 'csv' | 'xlsx'

const Main = ({pageConfig}: Props) => {


  // si on est en developpement on peut mettre un limit de 25 pour les tests
  let defaultPageLimit = 100;
  if(process.env.NODE_ENV === 'development'){
    defaultPageLimit = 25;
  }

  

  const [tableData, setTableData] = useState<any[][]>([]);
  const [aggregate, setAggregate] = useState<any>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [messageAlert, setMessageAlert] = useState<MessageAlert | null>();
  const [selected, setSelected] = useState<string[]>([]);
  const [openImport, setOpenImport] = useState<ImportType | null>(null)
  const [totalCount, setTotalCount] = useState(0);
  
  const params = useLocation()
  const { getParams, getFilterTable, getFilterTableId, setParams} = useAppNavigation()
  useEffect(() => {
    document.title = pageConfig.config.modelName;
  }, []);

  const handleGetData = async (forceLimit?: null | number): Promise<{ tableData: any[], totalCount: number, aggregate: any }> => {
    setIsLoaded(false);
    // this is done for the relation navigation
    const filter: any[] = getParams('filters') ? JSON.parse(getParams('filters')) : [] 
    if(getFilterTable()){
      filter.push({filter_by: getFilterTable(), filter_type: 'equal', filter_value: getFilterTableId()})
    }

    return await getAllRequest(pageConfig.config.apiUrl, {
      limit: forceLimit ? forceLimit : parseInt(getParams('limit') || defaultPageLimit.toString()),
      page: forceLimit ? 0 : parseInt(getParams('page') || '0'),
      search: getParams('search'),
      sort_by: getParams('sort_by'),
      asc: getParams('asc') === 'true',
      filters: filter,
      secondarySearch: getParams('secondarySearch'),
      ...pageConfig.config.customFilter
    }).then((data) => {
      setIsLoaded(true);
      return { tableData: data.result, totalCount: data.totalCount, aggregate: data.aggregate, more: data.more };
    });
  }

  useEffect(() => {
    if(pageConfig.config.defaultOrder){
      const defaultOrder = pageConfig.config.defaultOrder
      if(!getParams('sort_by')){
        setParams('sort_by', Object.keys(defaultOrder)[0])
        setParams('asc', defaultOrder[Object.keys(defaultOrder)[0]] === 'asc' ? 'true' : 'false')
      }
    }
    fetchData();
  }, [params]);

  const fetchData = async () => {
    try {
      const { tableData, totalCount, aggregate } = await handleGetData();
      setTableData(tableData);
      setTotalCount(totalCount);
      setAggregate(aggregate);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmitFileXML = (file: File) => {
    setLoadingImport(true);
    
      migrateXml('commandeMateriel', file,
        () => {
          setMessageAlert({ title: 'Success', text: 'XML Migrés', color: 'success' })
          setLoadingImport(false);
          fetchData()
        },
        (err) => {
          setMessageAlert({ title: 'Error', text: err, color: 'danger' })
          setLoadingImport(false);
        })
    
    
    
  }

  const handleSubmitFileCSV = async (file: File) => {
      setLoadingImport(true);
      const formData = new FormData();
      formData.append('file', file);

      await migrateCsv(
        file, 
        (resp) => {
          setMessageAlert({ title: 'Success', text: resp.msg, color: 'primary' })
          setLoadingImport(false);
          fetchData();
          exportExcelFile(
            resp.headers, 
            {tableData : resp.CSVData}, 
            'factures report'
          );

        }, 
        (error) => {
        setMessageAlert({ title: 'Error', text: error, color: 'danger' })
        setLoadingImport(false);
      })
  } 

  const itemList: ItemOption[] = []
  if(pageConfig.config.apiUrl === '/commandes'){
    itemList.push({ title: "Importer XML", onClick: () => setOpenImport('xml'), icon: 'CloudArrowUpIcon', color: 'success' },)
  }
  if(pageConfig.config.apiUrl === '/factures'){
    itemList.push({ title: "Importer CSV", onClick: () => setOpenImport('csv'), icon: 'CloudArrowUpIcon', color: 'success' },)
  }

  return (
    <>
      <TableHeaderLayout>
        <OrderAndSearch pageConfig={pageConfig} />
        <CustomAction pageConfig={pageConfig} onSuccess={fetchData} />
        <Filters pageConfig={pageConfig}/>
        {pageConfig.config.options.includes('create') && 
          <CreateForm onSuccess={fetchData} pageConfig={pageConfig} label='Options' />
        }
        <Options setSelected={setSelected} totalCount={totalCount} itemList={itemList} handleGetAllData={(e: null|number) => handleGetData(e)} pageConfig={pageConfig} selected={selected} refresh={fetchData} isDataLoading={!isLoaded}/>
      </TableHeaderLayout>
      <Table totalCount={totalCount} handleGetAllData={(e: null|number) => handleGetData(e)} onSuccess={fetchData} selected={selected} setSelected={setSelected} aggregate={aggregate} isLoaded={isLoaded} pageConfig={pageConfig} data={tableData} />
      <Pagination totalCount={totalCount} modelName={pageConfig.config.modelName} defaultPageLimit={defaultPageLimit}/>
      
      <AppDropZoneModal loading={loadingImport} message={messageAlert || null} open={openImport === 'xml'} 
          onClose={() => { setOpenImport(null); setTimeout(() => setMessageAlert(null), 1000) }} icon="CloudArrowUpIcon" onSubmit={handleSubmitFileXML} 
          acceptFile='.xml'
        />
      <AppDropZoneModal loading={loadingImport} message={messageAlert || null} open={openImport === 'csv'} 
        onClose={() => { setOpenImport(null); setTimeout(() => setMessageAlert(null), 1000) }} icon="CloudArrowUpIcon" onSubmit={handleSubmitFileCSV} 
        acceptFile='.csv'
      />
    </>
  );
};

export default Main;

import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import AppLoading from '../../components/AppLoading';

const SettingsView = lazy(() => import('./SettingsView'));

export default [
  <Route
    path="/settings"
    element={
      <Suspense fallback={<AppLoading />}>
        <SettingsView />
      </Suspense>
    }
  />
]

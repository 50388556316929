import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';
import { Input } from '@mui/joy';

interface props {
    selected: Date | undefined;
    setSelected: any;
    disabled?: boolean;
}
export default function AppDatePicker({ selected, setSelected, disabled = false }: props) {

    return (
        <div className='flex flex-col'>
            <Input
                disabled={disabled} 
                size='sm'
                type="date"
                value={selected ? format(selected, 'yyyy-MM-dd') : ''}
                onChange={(e) => setSelected(new Date(e.target.value))}
            />
        </div>
    );
}
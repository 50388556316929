import { Schema } from '../type';

export const personnePhysiqueSchemaVersionBtn: Schema = {
  style: { width: 100 },
  ref: 'personnePhysiqueVersionGroup/personnePhysiqueList',
  title: 'Versions',
  type: 'subSchema',
  subSchema: {
    display: 'drawer',
    multi: false,
    itemName: 'version',
    config: {
      modelName: 'version',
      itemName: 'une version',
      apiUrl: '/personnesPhysiques',
      options: ['update', 'newVersion'],
    },
    schemas: [
      {
        style: { width: 140 },
        ref: 'titre',
        type: 'select',
        valueMap: [
          { value: 'Monsieur', color: 'gray', icon: 'UserIcon' },
          { value: 'Madame', color: 'gray', icon: 'UserIcon' },
        ],
      },
      {
        style: { width: 140 },
        ref: 'nom',
        search: true,
        form: { requier: true },
      },
      {
        style: { width: 140 },
        ref: 'prenom',
        search: true,
        form: { requier: true },
      },
      {
        style: { width: 140 },
        ref: 'version',
        type: 'amount',
        ban: ['update', 'create'],
      },
      { style: { width: 140 }, ref: 'telephone' },
      { style: { width: 180 }, ref: 'email', type: 'email' },
      { style: { width: 140 }, ref: 'notes', type: 'note' },
      {
        style: { width: 100 },
        ref: 'status',

        type: 'select',
        valueMap: [
          { value: 'actif', color: 'green', icon: 'CheckIcon' },
          { value: 'inactif', color: 'red', icon: 'XMarkIcon' },
        ],
      },
      {
        style: { width: 140 },
        ref: 'role',
        type: 'select',
        valueMap: [
          { value: 'decideur', color: 'yellow' },
          { value: 'patron', color: 'blue' },
        ],
      },
      {
        style: { width: 140 },
        ref: 'version',
        type: 'amount',
        ban: ['update', 'create', 'updateMany'],
      },
    ],
  },
};

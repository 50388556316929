import { useAppFiltersUrl } from "@app/system/hooks/useAppFiltersUrl";
import { FormControl, FormLabel } from "@mui/joy"
import { useEffect, useState } from "react";
import RequestField from "@app/main/forms/components/RequestField";
import axios from "axios";
type props = {
    filterBy: string
}


const FilterUserLogin = ({filterBy}:props) => {

    const {autoUpdateFilter} = useAppFiltersUrl()
    const [selectedUserLogin, setSelectedUserLogin] = useState<string>('')

    const [userCollaborateur, setUserCollaborateur] = useState<string>('')

    useEffect(() => {
        const collab = async () => {
            const res = await axios.get(`/user-login/collaborateur`)
            setUserCollaborateur(res.data.map((user:any) => {return {id: user.id, userName: user.userName}}))
        }
        collab()
    }, [])

    useEffect(() => {
        if(selectedUserLogin !== null && selectedUserLogin !== undefined){
            autoUpdateFilter(
                {   
                    filter_by: filterBy,
                    filter_type: 'special',
                    filter_value: selectedUserLogin,
                }, 
            )
        }
    }, [selectedUserLogin])

    return (
        <>
            <FormControl size="sm">
                <FormLabel>Collaborateur</FormLabel>
                <RequestField apiUrl='/user-login' keyRelation='userName' keyResult='userName' type='select' onChange={(e) => setSelectedUserLogin(e)} value={selectedUserLogin ? selectedUserLogin : ''} />
            </FormControl>
        </>
    )
}

export default FilterUserLogin
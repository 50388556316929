import * as React from 'react';
import {
    FormControl, FormLabel, Button,
} from '@mui/joy';

import { AppIcon } from '../../components/AppIcon';
import { AppModal } from '../../components/AppModal';
import { PageConfig } from "@app/pages";
import { Form } from "./components/Form";
import { useState } from "react"
import { useAppForm, DataItem } from "@app/system/hooks/useAppForm"
import axios from '../../config/axios.config';
import { MessageAlert } from '@app/components/AppAlert';
import AppAlert from '@app/components/AppAlert';
import { returnErrorMsg } from '@app/system/utils/basic';

interface Props {
    label?: string,
    pageConfig: PageConfig,
    onSuccess: () => void,
}
export const CreateForm = ({ label, pageConfig, onSuccess }: Props) => {
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const { createEmptyFormData, createDataReq, checkFormDataValidation, resetFormError } = useAppForm()
    const [formData, setFormData] = useState<DataItem[]>(createEmptyFormData(pageConfig.schemas))
    const [fromError, setFormError] = useState<MessageAlert|null>(null)
    const updateFormData = (index: number, value: string) => {
        const formDataCopy: DataItem[] = [... formData]
        formDataCopy[index].value = value
        setFormData(formDataCopy);
    }

    const handleClose = () => {
        setOpenModal(false)
        setFormData(resetFormError(formData))
        setFormError(null)
    }

    const handleModalSubmit = async () => {
        const {newFormData, isError} = checkFormDataValidation(formData)
        setFormData(newFormData);
        if(isError)return;
        const dataResult: any = createDataReq(formData, false)
        await axios.post(pageConfig.config.apiUrl, dataResult).then(() => {
            setOpenModal(false);
            setFormData(resetFormError(formData))
            setFormData(createEmptyFormData(pageConfig.schemas))
            onSuccess();
        }).catch((err) => {
            console.error('err->', err)
            setFormError({title:'Erreur', text: returnErrorMsg(err) , color: 'danger',  })
        });
    }

    return (
        <>
            <AppModal handleSubmit={handleModalSubmit} icon='PlusIcon' title={`Ajouter ${pageConfig.config.itemName}`} submitText="Ajouter" open={openModal} onClose={handleClose}>
                <Form updateFormData={updateFormData} formData={formData}></Form>
                {fromError && <div className="mt-4"><AppAlert message={fromError} /></div>}
            </AppModal>

            <FormControl size="sm">
                {label && <FormLabel>{label}</FormLabel>}
                <Button
                    onClick={() => setOpenModal(true)}
                    variant="soft"
                    size='sm'
                    startDecorator={<AppIcon icon="PlusIcon" />}
                    color="primary"
                >
                    Ajouter {pageConfig.config.itemName}
                </Button>
            </FormControl>
        </>
    )
}
import { personneMoraleSchemaVersionBtn } from '../subSchemas/personneMoraleVersionBtn';
import { PageConfig } from '../type';

export const parrainageSchema: PageConfig = {
  config: {
    modelName: 'Parrainages',
    itemName: 'un parrainage',
    apiUrl: '/parrainages',
    options: [
      'create',
      'update',
      'updateMany',
      'delete',
      'filter',
      'order',
      'deleteMany',
    ],
    banExport: ['pdf'],
    defaultOrder: { ['facture/numeroFacture']: 'asc' },
    secondarySearch: 'Numero Facture',
  },

  relations: [
    {
      labelName: 'Personne Morale',
      getKeyInData: 'personneMorale',
      postKeyDb: 'personneMorale/id',
      getApiUrlOptions: '/personnesMorales',
      displayFieldOption: [
        'raisonSociale',
        'adresse/ligne1',
        'adresse/region',
        'adresse/casePostal',
        'adresse/NPA',
        'telephone',
        'email',
      ],
      asVersion: true,
    },
    {
      labelName: 'Personne Physique Direct',
      getKeyInData: 'personnePhysique',
      postKeyDb: 'personnePhysique',
      getApiUrlOptions: '/personnesPhysiques',
      displayFieldOption: ['nom', 'prenom', 'titre', 'telephone', 'email'],
      asVersion: true,
      many: true,
    },
    // { modelName: 'Personne Physique', relationDbName: 'personnePhysique', apiUrl: '/personnesPhysiques', displayField: 'nom', many: true },
  ],

  schemas: [
    {
      style: { width: 140, fixed: true },
      ref: 'facture/numeroFacture',
      title: 'Numero Facture',
      search: true,
      form: { display: false },
    },
    {
      style: { width: 0, isDisplayInTable: false },
      ref: 'mandatId',
      title: 'mandat/ref',
      form: {
        requier: true,
        select: {
          apiUrl: '/mandats',
          fieldName: 'ref',
          fieldResult: 'id',
          type: 'autocomplete',
        },
      },
    },
    {
      style: { width: 0, isDisplayInTable: false },
      ref: 'personneMorale/id',
      title: 'Personne Morale',
      form: {
        requier: true,
        select: {
          apiUrl: '/personnesMorales',
          fieldName: 'raisonSociale',
          fieldResult: 'id',
          type: 'search',
        },
      },
    },
    {
      style: { width: 220 },
      ref: 'personneMorale',
      type: 'subSchema',
      subSchema: {
        display: 'table',
        multi: false,
        itemName: 'personne morale',
        config: {
          modelName: 'personneMorale',
          itemName: 'une personne morale',
          apiUrl: '/personnesMorales',
          options: [
            'create',
            'add',
            'update',
            'delete',
            'newVersion',
            // 'createNewVersion',
          ],
        },
        schemas: [
          { style: { width: 200 }, ref: 'raisonSociale' },
          {
            style: { width: 140 },
            ref: 'adresse/ligne1',
            title: 'Adresse',
          },
          {
            style: { width: 140 },
            ref: 'adresse/region',
            title: 'Localité',
          },
          {
            style: { width: 140 },
            ref: 'adresse/casePostal',
            title: 'Case Postale',
          },
          {
            style: { width: 140 },
            ref: 'adresse/NPA',
            title: 'NPA',
          },
          { style: { width: 140 }, ref: 'telephone' },
          { style: { width: 180 }, ref: 'email' },
          { style: { width: 140 }, ref: 'notes', type: 'note' },
          {
            style: { width: 80 },
            ref: 'version',
            type: 'amount',
            ban: ['create', 'update'],
          },
          personneMoraleSchemaVersionBtn,
        ],
      },
    },
    {
      style: { width: 300 },
      ref: 'personneMorale/personnePhysique',
      type: 'subSchema',
      subSchema: {
        display: 'table',
        multi: true,
        itemName: 'personne physique',
        config: {
          modelName: 'personnePhysique',
          itemName: 'une personne physique',
          apiUrl: '/personnesPhysiques',
          options: [
            'create',
            'add',
            'update',
            'delete',
            'newVersion',
            'createNewVersion',
          ],
        },
        schemas: [
          { style: { width: 120 }, ref: 'nom' },
          { style: { width: 120 }, ref: 'prenom' },
          {
            style: { width: 120 },
            ref: 'titre',
            type: 'select',
            valueMap: [
              { value: 'Monsieur', color: 'gray', icon: 'UserIcon' },
              { value: 'Madame', color: 'gray', icon: 'UserIcon' },
            ],
          },
          { style: { width: 160 }, ref: 'telephone' },
          { style: { width: 220 }, ref: 'email' },
          {
            style: { width: 100 },
            ref: 'status',
            type: 'select',
            valueMap: [
              { value: 'actif', color: 'green', icon: 'CheckIcon' },
              { value: 'inactif', color: 'red', icon: 'XMarkIcon' },
            ],
          },
        ],
      },
    },
    {
      style: { width: 220 },
      ref: 'accordDe',
      type: 'subSchema',
      subSchema: {
        display: 'table',
        multi: true,
        itemName: 'personne physique',
        config: {
          modelName: 'personnePhysique',
          itemName: 'une personne physique',
          apiUrl: '/personnesPhysiques',
          options: [
            'create',
            'add',
            'update',
            'delete',
            'newVersion',
            'createNewVersion',
          ],
        },
        schemas: [
          { style: { width: 120 }, ref: 'nom' },
          { style: { width: 120 }, ref: 'prenom' },
          {
            style: { width: 120 },
            ref: 'titre',
            type: 'select',
            valueMap: [
              { value: 'Monsieur', color: 'gray', icon: 'UserIcon' },
              { value: 'Madame', color: 'gray', icon: 'UserIcon' },
            ],
          },
          { style: { width: 160 }, ref: 'telephone' },
          { style: { width: 220 }, ref: 'email' },
          {
            style: { width: 100 },
            ref: 'status',
            type: 'select',
            valueMap: [
              { value: 'actif', color: 'green', icon: 'CheckIcon' },
              { value: 'inactif', color: 'red', icon: 'XMarkIcon' },
            ],
          },
        ],
      },
    },
    {
      style: { width: 240 },
      ref: 'isDirectPhysique',
      title: 'Personne Physique Direct',
      type: 'bool',
      ban: ['create', 'update'],
    },
    {
      style: { width: 300 },
      ref: 'personnePhysique',
      title: 'Personne Physique Directe',
      type: 'subSchema',
      subSchema: {
        display: 'table',
        multi: true,
        itemName: 'personne physique directe',
        config: {
          modelName: 'personnePhysique',
          itemName: 'une personne physique',
          apiUrl: '/personnesPhysiques',
          options: [
            'create',
            'add',
            'update',
            'delete',
            'newVersion',
            'createNewVersion',
          ],
        },
        schemas: [
          { style: { width: 120 }, ref: 'nom' },
          { style: { width: 120 }, ref: 'prenom' },
          {
            style: { width: 120 },
            ref: 'titre',
            type: 'select',
            valueMap: [
              { value: 'Monsieur', color: 'gray', icon: 'UserIcon' },
              { value: 'Madame', color: 'gray', icon: 'UserIcon' },
            ],
          },
          { style: { width: 160 }, ref: 'telephone' },
          { style: { width: 220 }, ref: 'email' },
          {
            style: { width: 100 },
            ref: 'status',
            type: 'select',
            valueMap: [
              { value: 'actif', color: 'green', icon: 'CheckIcon' },
              { value: 'inactif', color: 'red', icon: 'XMarkIcon' },
            ],
          },
        ],
      },
    },

    { style: { width: 180 }, ref: 'raisonSocialePj' },
    {
      style: { width: 140 },
      ref: 'dateAccord',
      type: 'date',
      form: { requier: true },
    },
    {
      style: { width: 180 },
      ref: 'facture/libelleFacture',
      type: 'pargraphe2',
      form: { display: false },
    },
    { style: { width: 220 }, ref: 'coordonneesCorrespondance' },
    { style: { width: 180 }, ref: 'accuseDeReception', type: 'bool' },
    { style: { width: 140 }, ref: 'noteInterne', type: 'HTMLText' },
    {
      style: { width: 200 },
      ref: 'publicite',
      type: 'chip',
      ban: ['order', 'filter'],
    },
    { style: { width: 140 }, ref: 'reconduction', type: 'bool' },
    { style: { width: 140 }, ref: 'annonceOk', type: 'bool' },
    { style: { width: 140 }, ref: 'mandat/ref', form: { display: false } },
    {
      style: { width: 140 },
      ref: 'mandat/club/nom',
      title: 'Nom Club',
      form: { display: false },
    },

    { style: { width: 140 }, ref: 'montantPromesse', type: 'money' },
    {
      style: { width: 240 },
      ref: 'facture/montantPayeHorsTva',
      type: 'money',
      form: { display: false },
    },
    {
      style: { width: 240 },
      ref: 'noteLn',
      title: 'Note LN',
      type: 'pargraphe2',
    },
    { style: { width: 140 }, ref: 'datesRappels', type: 'date' },
    {
      style: { width: 0, isDisplayInTable: false },
      ref: 'collaborateurId',
      title: 'collaborateur',
      form: {
        requier: true,
        select: {
          apiUrl: '/users',
          fieldName: 'ref',
          fieldResult: 'id',
          type: 'select',
        },
      },
    },
    {
      style: { width: 140 },
      ref: 'collaborateur/ref',
      form: { display: false },
    },
    { style: { width: 140 }, ref: 'tauxCommission', type: 'percent' },
    { style: { width: 140 }, ref: 'commissionPayee', type: 'money' },
    { style: { width: 180 }, ref: 'dateCommissionPayee', type: 'date' },
    { style: { width: 140 }, ref: 'soldePaye', type: 'money' },
    { style: { width: 140 }, ref: 'dateSoldePaye', type: 'date' },
    { style: { width: 160 }, ref: 'ristourneAcvfPayee', type: 'money' },
    { style: { width: 200 }, ref: 'dateRistourneAcvfPayee', type: 'date' },
    {
      style: { width: 140 },
      ref: 'potentielId',
      type: 'ref',
      form: { display: false },
    },
    {
      style: { width: 140 },
      ref: 'verifiedLeadId',
      type: 'ref',
      form: { display: false },
    },
    {
      style: { width: 140 },
      ref: 'facture/paiement',
      type: 'bool',
      ban: ['create'],
    },
    {
      style: { width: 180 },
      ref: 'facture/datePaiement',
      type: 'date',
      ban: ['updateMany', 'create'],
    },
    { style: { width: 140 }, ref: 'annulation', type: 'bool', ban: ['create'] },
    {
      style: { width: 140 },
      ref: 'dateAnnulation',
      type: 'date',
      ban: ['updateMany', 'create'],
    },
    { style: { width: 140 }, ref: 'storno', type: 'bool', ban: ['create'] },
    {
      style: { width: 140 },
      ref: 'dateStorno',
      type: 'date',
      ban: ['updateMany', 'create'],
    },
    {
      style: { width: 140 },
      ref: 'stornoReactive',
      type: 'bool',
      ban: ['create'],
    },
    {
      style: { width: 160 },
      ref: 'dataStornoReactive',
      type: 'date',
      ban: ['updateMany', 'create'],
    },
    {
      style: { width: 140 },
      ref: 'langue',
      type: 'select',
      valueMap: [
        { value: 'FR', color: 'blue', icon: 'GlobeEuropeAfricaIcon' },
        { value: 'DE', color: 'yellow', icon: 'GlobeEuropeAfricaIcon' },
      ],
    },
  ],
};

import { lazy, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { useAppNavigation } from '@app/system/hooks/useAppNavigation';
import Main from './Main';

// view
import { parrainageViewSchema, factureViewSchema, parrainagesCurrentMonthSchema, 
  materialDueToClubViewSchema, ballonsDueToClubViewSchema, reconductionsABloquerSchema,
  reconductionsPourImpressionSchema, reconductionsPourAssistantPotentielSchema, listeNoireViewSchema,
} from '@app/pages';

// data
import { clubSchema, commandeSchema, factureSchema, 
  fournisseurSchema, parrainageSchema, mandatSchema, personneMoraleSchema, 
  personnePhysiqueSchema, userSchema, ristourneSchema  } from '@app/pages';
import { listeNoireUserSchema } from '@app/pages/collaborateur/listNoire';
import { useAuth } from '@app/system/context/authContext';
import { UserRoles } from '@app/api';
import listeNoireUserAdminSchema from '@app/pages/collaborateur/listNoire.admin';


const RenderCompLastUrl = () => {
  const { getCurrentTable, getBaseApp } = useAppNavigation()
  const baseApp = getBaseApp()
  const lastSegment = getCurrentTable()
  
  const {getTokenInfo} = useAuth();
  const roles = getTokenInfo()?.roles;
  // need to switch to admin 
  const isAdmin = roles?.includes(UserRoles.ADMIN);

  const urlToSchemaDATA = {
    clubs: clubSchema,
    commandes: commandeSchema,
    factures: factureSchema,
    fournisseurs: fournisseurSchema,
    parrainages: parrainageSchema,
    mandats: mandatSchema,
    personneMorales: personneMoraleSchema,
    personnePhysiques: personnePhysiqueSchema,
    collaborateurs: userSchema,
    ristournes: ristourneSchema,
  }
  
  const urlToSchemaVIEW = {
    parrainages: parrainageViewSchema,
    factures: factureViewSchema,
    parrainagesCurrentMonth: parrainagesCurrentMonthSchema,
    materialDueToClub: materialDueToClubViewSchema,
    ballonDueToClub: ballonsDueToClubViewSchema,
    reconductionsABloquerPourAssistantPotentiel: reconductionsABloquerSchema,
    reconductionsPourImpression: reconductionsPourImpressionSchema,
    reconductionsPourAssistantPotentiel: reconductionsPourAssistantPotentielSchema,
    listeNoire: listeNoireViewSchema,
  }

  const urlToSchemaUSER = {
    listeNoire: isAdmin ? listeNoireUserAdminSchema : listeNoireUserSchema,
  }

  return (
    <>
      {baseApp === 'data' &&
        <>
          {Object.entries(urlToSchemaDATA).map((UrlToSch, index) => (
              UrlToSch[0].toString() === lastSegment && 
              <Fragment key={index}>
                <Main pageConfig={UrlToSch[1]} />
              </Fragment>
          ))}
        </>
      }
      {baseApp === 'view' &&
        <>
          {Object.entries(urlToSchemaVIEW).map((UrlToSch, index) => (
              UrlToSch[0].toString() === lastSegment &&  
              <Fragment key={index}>
                <Main pageConfig={UrlToSch[1]} />
              </Fragment>
            ))}
        </>
      }
      {baseApp === 'user' &&
        <>
          {Object.entries(urlToSchemaUSER).map((UrlToSch, index) => (
              UrlToSch[0].toString() === lastSegment &&  
              <Fragment key={index}>
                <Main pageConfig={UrlToSch[1]} />
              </Fragment>
            ))}
        </>
      }
    </>
  )
}
export default [
  <Route
    path={`/tables/*`}
    element={<RenderCompLastUrl />}
  />,
]






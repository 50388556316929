import { PageConfig } from '../type';

export const factureSchema: PageConfig = {
  config: {
    modelName: 'Factures',
    itemName: 'une facture',
    apiUrl: '/factures',
    options: ['update', 'updateMany', 'filter', 'order'],
    defaultOrder: { numeroFacture: 'asc' },
    secondarySearch: 'Numero Facture',
  },
  schemas: [
    {
      style: { width: 200 },
      ref: 'libelleFacture',
      type: 'pargraphe2',
      search: true,
    },
    { style: { width: 140 }, ref: 'nbParrainage', type: 'amount' },
    { style: { width: 140 }, ref: 'numeroFacture', search: true },
    { style: { width: 140 }, ref: 'dateCreation', type: 'date' },
    { style: { width: 140 }, ref: 'noteAnnonce', type: 'note' },
    { style: { width: 140 }, ref: 'anfChoixAnnonce' },
    { style: { width: 140 }, ref: 'noteInterne', type: 'note' },
    {
      style: { width: 140 },
      ref: 'qrRef',
      ban: ['updateMany', 'update', 'create'],
    },
    { style: { width: 140 }, ref: 'logo', type: 'bool' },
    {
      style: { width: 140 },
      ref: 'contractType',
      type: 'chip',
      valueMap: [
        { value: 'Materiel', color: 'blue' },
        { value: 'ACVF', color: 'blue' },
      ],
    },
    { style: { width: 140 }, ref: 'paiement', type: 'bool' },
    { style: { width: 200 }, ref: 'datePaiement', type: 'date' },
    { style: { width: 200 }, ref: 'parrainage/dateAccord', type: 'date' },
    {
      style: { width: 220 },
      ref: 'parrainage/montantPromesse',
      type: 'money',
      form: { display: false },
    },
    { style: { width: 140 }, ref: 'montantTva', type: 'money' },
    { style: { width: 200 }, ref: 'montantPromesseTva', type: 'money' },
    { style: { width: 200 }, ref: 'montantPayeHorsTva', type: 'money' },
    { style: { width: 140 }, ref: 'montantInt', type: 'amount' },
    { style: { width: 140 }, ref: 'montantCent', type: 'amount' },
    {
      style: { width: 140 },
      ref: 'tauxDeTVA',
      title: 'Taux De TVA',
      type: 'select',
      valueMap: [
        { value: '7.7', color: 'blue' },
        { value: '8.1', color: 'blue' },
      ],
    },
  ],
};

import { Schema } from "@app/pages"
import * as React from "react"
import { RenderValue } from "./RenderValue"
import { useAppSchema } from "@app/system/hooks/useAppSchema"

interface props {
    schemas: Schema[],
    data: any,
}
export const RenderAggregateValue = ({ schemas, data }: props) => {
    const {getData} = useAppSchema()
    
    return (
        <>
            <tr>
                <td style={{ textAlign: 'center', width: 120, zIndex: '99' }}>
                    <div>Total</div>
                </td>
                {schemas.map((schema, schemaIndex) => (
                    schema.style?.isDisplayInTable !== false &&
                    <td key={schemaIndex}>
                        <React.Fragment >
                            {getData(data, schema.ref) && <RenderValue onSuccess={() => {}} schema={schema} field={getData(data, schema.ref)} id={0} />}
                        </React.Fragment>
                    </td>
                ))}
                <td></td>
            </tr>
        </>
    )
}

export default RenderAggregateValue
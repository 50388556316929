import { Schema } from "@app/pages"
import { Typography } from '@mui/joy';
import { StatusChip } from './StatusChip';
import { parseISO } from 'date-fns'
import { displayMoneyAmount } from '@app/system/utils/basic';
import { RelationBtn } from './RealationBtn';
import { SubRelation } from './SubRelation';
import { RenderRow } from "./RenderRow";
import { stringToHtml, decodeHTMLEntities } from "@app/system/utils/styleUtils";

interface props {
    schema: Schema,
    field: any,
    id: number,
    onSuccess: () => void,
}
export const RenderValue = ({ schema, field, id, onSuccess }: props) => {
    const empty = <div className="opacity-40">---</div>

    const renderValue = (schema: Schema, field: any, id: number,) => {
        
        if (schema.type === 'recursive') {
            return schema.subSchema && <RenderRow schemas={schema.subSchema.schemas} data={field} id={id} onSuccess={onSuccess}/>
        }
        if (field === undefined || field === null || field === '') {
            return empty
        }
        if (!schema.type) {
            return <Typography level="body-xs">
              {field?.includes(';') ? field.split(';').map((f: string, i: number) => (
                <div key={i} className='whitespace-pre-line'>{f}</div>
              )) : field}
            </Typography>
        }
        if (schema.type === "note") {
            return <div className="p-1 rounded-md"><Typography level="body-xs">{stringToHtml(field)}</Typography></div>
        }
        if( schema.type === 'HTML') {
            return <Typography level="body-xs"><div dangerouslySetInnerHTML={{ __html: stringToHtml(field) }} /></Typography>
        }
        if( schema.type === 'HTMLText') {
            return <Typography level="body-xs">{decodeHTMLEntities(field)}</Typography>
        }
        if (schema.type === "date-full") {
            return <StatusChip solid={true} color='gray' title={parseISO(field).toLocaleString()} icon='CalendarIcon' />
        }
        if (schema.type === "date") {
            return <StatusChip solid={true} color='gray' title={parseISO(field).toLocaleString().replace(',','').split(' ')[0]} icon='CalendarIcon' />
        }
        if (schema.type === "bool") {
            return <StatusChip solid={false} iconEnd={true} color={field === true ? 'green' : 'red'} title='' icon={field === true ? 'CheckCircleIcon' : 'XCircleIcon'} />
        }
        if (schema.type === "percent") {
            return <StatusChip solid={false} iconEnd={false} color='red' title={field + ' %'} />
        }
        if (schema.type === "email") {
            return <StatusChip solid={true} iconEnd={false} color='blue' title={field} icon='EnvelopeIcon' />
        }
        if (schema.type === "phone") {
            return <StatusChip solid={true} iconEnd={false} color='blue' title={field} icon='PhoneIcon' />
        }
        if (schema.type === "money") {
            field = field.toString()
            return <StatusChip solid={false} color='yellow' title={displayMoneyAmount(field)} />
        }
        if (schema.type === "float") {
            field = field.toString()
            return <StatusChip solid={false} color='yellow' title={displayMoneyAmount(field)} />
        }
        if (schema.type === "ref") {
            return <div className="flex items-center">
                <Typography level="body-xs">{field}</Typography></div>
        }
        if (schema.type === "amount") {
            return <StatusChip color='blue' title={field} />
        }
        if (schema.type === 'chip') {
            let color = 'gray';
            let icon;
            return <StatusChip color={color} title={field} icon={icon} />
        }
        if (schema.type === 'select'){
            let color = 'gray';
            let icon;
            if (schema.valueMap) {
                const find = schema.valueMap.find((value) => value.value === field)
                if (find) {
                    color = find.color
                    icon = find.icon
                }
            }
            return <StatusChip color={color} title={field} icon={icon} />
        }
        if (schema.type === 'relation') {
            return <RelationBtn field={field} schema={schema} id={id} />
        }
        if (schema.type === 'subSchema') {
            return <SubRelation field={field} schema={schema} id={id} onSuccess={onSuccess} />
        }
        if(schema.type === 'pargraphe'){
            let valueWithNewlines = field.replace(/<br>/g, '\n');
            const ligne: string[] = valueWithNewlines.split('\n') 
            return ligne.map((line, index) => <Typography key={index} level="body-xs"> {line} </Typography>)
        }
        if(schema.type === 'pargraphe2'){ 
            return <Typography level="body-xs" className="whitespace-pre-line">
                {field}
            </Typography>
        }
    }
    return renderValue(schema, field, id)
}
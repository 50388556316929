import { personneMoraleSchemaVersionBtn } from '../subSchemas/personneMoraleVersionBtn';
import { personnePhysiqueSchemaVersionBtn } from '../subSchemas/personnePhysiqueVersionBtn';
import { PageConfig } from '../type';

export const personneMoraleSchema: PageConfig = {
  config: {
    modelName: 'Personnes morales',
    itemName: 'une personne morale',
    apiUrl: '/personnesMorales',
    options: [
      'create',
      'update',
      'updateMany',
      'delete',
      'filter',
      'order',
      'deleteMany',
      'createNewVersion',
      'newVersion',
    ],
  },

  relations: [
    {
      labelName: 'Personnes Physiques',
      getKeyInData: 'personnePhysique',
      postKeyDb: 'personnePhysique',
      getApiUrlOptions: '/personnesPhysiques',
      displayFieldOption: ['nom', 'prenom', 'telephone', 'email'],
      // asVersion: true,
      many: true,
    },
  ],

  schemas: [
    {
      style: { width: 140 },
      ref: 'customFacturePersonneMoral',
      title: 'Numero Facture',
      type: 'custom',
      form: { display: false },
    },
    {
      style: { width: 140 },
      ref: 'raisonSociale',
      form: { requier: true },
      search: true,
    },
    {
      style: { width: 220 },
      ref: 'personnePhysique',
      type: 'subSchema',
      subSchema: {
        display: 'table',
        multi: true,
        itemName: 'personne physique',
        config: {
          modelName: 'personnePhysique',
          itemName: 'une personne physique',
          apiUrl: '/personnesPhysiques',
          options: [
            'create',
            'add',
            'update',
            'delete',
            'newVersion',
            'createNewVersion',
          ],
        },
        schemas: [
          {
            style: { width: 120 },
            ref: 'titre',
            type: 'select',
            valueMap: [
              { value: 'Monsieur', color: 'gray', icon: 'UserIcon' },
              { value: 'Madame', color: 'gray', icon: 'UserIcon' },
            ],
          },

          { style: { width: 120 }, ref: 'nom' },
          { style: { width: 120 }, ref: 'prenom' },
          { style: { width: 160 }, ref: 'telephone' },
          { style: { width: 220 }, ref: 'email' },
          {
            style: { width: 100 },
            ref: 'status',
            type: 'select',
            valueMap: [
              { value: 'actif', color: 'green', icon: 'CheckIcon' },
              { value: 'inactif', color: 'red', icon: 'XMarkIcon' },
            ],
          },
          {
            style: { width: 80 },
            ref: 'version',
            type: 'amount',
            ban: ['create', 'update'],
          },

          {
            style: { width: 140 },
            ref: 'parrainagePersonnePhysique',
            title: 'Personne',
            type: 'relation',
            redirect: {
              itemName: 'parrainage',
              to: 'parrainages',
              from: 'personnePhysiques',
            },
          },
          {
            style: { width: 140 },
            ref: 'parrainageAccordDe',
            title: 'Accord De',
            type: 'relation',
            redirect: {
              itemName: 'parrainage',
              to: 'parrainages',
              from: 'accordDe',
            },
          },
          personnePhysiqueSchemaVersionBtn,
        ],
      },
    },
    { style: { width: 140 }, ref: 'telephone' },
    { style: { width: 140 }, ref: 'email' },
    { style: { width: 140 }, ref: 'notes', type: 'note' },
    {
      style: { width: 100 },
      ref: 'status',
      type: 'select',
      valueMap: [
        { value: 'actif', color: 'green', icon: 'CheckIcon' },
        { value: 'inactif', color: 'red', icon: 'XMarkIcon' },
      ],
    },
    {
      style: { width: 140 },
      ref: 'version',
      type: 'amount',
      ban: ['update', 'create', 'updateMany'],
    },
    personneMoraleSchemaVersionBtn,
  ],
};

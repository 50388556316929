import { PageConfig } from '../type';

export const fournisseurSchema: PageConfig = {
  config: {
    modelName: 'Fournisseurs',
    itemName: 'un fournisseur',
    apiUrl: '/fournisseurs',
    options: ['create', 'update', 'updateMany', 'delete', 'filter', 'order'],
  },
  schemas: [
    { style: { width: 140 }, ref: 'nom', form: { requier: true } },
    { style: { width: 140 }, ref: 'telephone' },
    { style: { width: 140 }, ref: 'email', type: 'email' },
    { style: { width: 140 }, ref: 'notes', type: 'note' },
    {
      style: { width: 100 },
      ref: 'status',
      type: 'select',
      valueMap: [
        { value: 'actif', color: 'green', icon: 'CheckIcon' },
        { value: 'inactif', color: 'red', icon: 'XMarkIcon' },
      ],
    },
    // {
    //   style: { width: 140 },
    //   ref: 'commandeMateriel',
    //   type: 'relation',
    //   redirect: {
    //     to: 'commandes',
    //     itemName: 'commandeMateriel',
    //   },
    // },
  ],
};

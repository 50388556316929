import { Schema } from "@app/pages";
import { SubDrawerBtn } from "./SubDrawerBtn";
import { SubTableBtn } from "./SubTableBtn";
import { SubFullTableBtn } from "./SubFullTableBtn";

interface Props {
    field: any
    schema: Schema
    id: number
    onSuccess: () => void
}

export const SubRelation = ({ field, schema, id, onSuccess }: Props) => {
    if (!schema.subSchema) {
        throw new Error('You need a subSchema')
    }
    let title;
    let data;

    if (Array.isArray(field)) {
        data = field
        title = field.length + ' ' + schema.subSchema.itemName
    } else {
        data = [field]
        title = schema.subSchema.itemName
    }

    return (
        <>
            {schema.subSchema.display === 'drawer' && 
                <SubDrawerBtn data={data} pageConfig={schema.subSchema} title={title} amount={data.length} onSuccess={onSuccess} />
            }
                
            {schema.subSchema.display === 'table' && 
                <SubTableBtn data={data} pageConfig={schema.subSchema} title={title} amount={data.length} onSuccess={onSuccess}/>
            }

            {schema.subSchema.display === 'api' && 
                <SubFullTableBtn idList={data} pageConfig={schema.subSchema} title={title} amount={data.length}/>
            }
        </>
    )
} 
import { IconName, AppIcon } from "@app/components/AppIcon"
import { FormLabel, FormControl, Option, Select, ListItemDecorator, Input, IconButton, Box } from "@mui/joy"
import { useState, useRef, useEffect } from "react"
import { PageConfig } from "@app/pages"
import AppDatePickerRangeModal from "@app/components/AppDatePickerRangeModal"
import { SelectItem, useAppSchema } from "@app/system/hooks/useAppSchema"

type FilterListItem = {
    title: string;
    value: string;
    action?: 'openDate'|'openMultiDate'|'text'|'select';
    icon?: IconName;
};

const filterDisableAction = ['select_month_metier', 'select_month', 'current_month_metier', 'current_month', 'current_year', '2022', '2021', '2020', '2019', '2018', '2017', 'true', 'false'] 

const filterDateList: FilterListItem[] = [
    { icon: 'CalendarDaysIcon', title: 'Debut - Fin', value: 'start_end', action: 'openMultiDate' },
    { icon: 'CalendarIcon', title: 'Mois en cours (25-24)', value: 'current_month_metier' },
    { icon: 'CalendarIcon', title: 'Mois en cours', value: 'current_month' },
    { icon: 'CalendarIcon', title: 'Année en cours', value: 'current_year' },
    { icon: 'CalendarIcon', title: '2023', value: '2023' },
    { icon: 'CalendarIcon', title: '2022', value: '2022' },
    { icon: 'CalendarIcon', title: '2021', value: '2021' },
    { icon: 'CalendarIcon', title: '2020', value: '2020' },
    { icon: 'CalendarIcon', title: '2019', value: '2019' },
    { icon: 'CalendarIcon', title: '2018', value: '2018' },
    { icon: 'CalendarIcon', title: '2017', value: '2017' },
]

const filterTextList: FilterListItem[] = [
    { title: 'Contient', value: 'contain', action: 'text'},
    { title: 'Commence par', value: 'start_with',  action: 'text' },
    { title: 'Finit par', value: 'end_with',  action: 'text' },
    { title: 'Contient (***, ***, ***)', value: 'contain_multi',  action: 'text' }
]

const filterAmountList: FilterListItem[] = [
    { title: '<=> fourchette (***,***)', value: 'range',  action: 'text'},
    { title: '= égal', value: 'equal',  action: 'text'},
    { title: '< inférieur', value: 'under',  action: 'text'},
    { title: '> supérieur ', value: 'over',  action: 'text'},
]

const filterBooleanList: FilterListItem[] = [
    { title: 'Vrai', value: 'true' },
    { title: 'Faux', value: 'false' },
]


export type filterItemType = {
    by: string
    type: string
    value: string
}

interface FilterItemProps {
    first?: boolean;
    pageConfig: PageConfig;
    filter: filterItemType,
    removeFilter: () => void;
    updateFilter: (filter: filterItemType) => void;
}


export const FilterItem = ({first, pageConfig, filter, removeFilter , updateFilter  }: FilterItemProps) => {
    const { transformSchemaTypeToApiFilters, renderFilterIcon, getSelectItemList } = useAppSchema()
    const [open, setOpen] = useState<boolean>(false)
    const [action, setAction] = useState<string>('')
    const [displayValueField, setDisplayValueField] = useState<string>(filter.value)
    
    const handleChangeFilterBy = (value: string) => {
        updateFilter({by: value, type: '', value: ''})
    };

    const handleChangFilterType = (filterListItem: FilterListItem, valueMap: boolean = false) => {
        if(valueMap){
            updateFilter({by: filter.by, type: 'is_equal', value: filterListItem.value})
        }else{
            if(filterListItem.action){
                setAction(filterListItem.action)
                updateFilter({by: filter.by, type: filterListItem.value, value: ''})

            }else{
                updateFilter({by: filter.by, type: filterListItem.value, value: filterListItem.value})
                setAction('')
            }
        }   
        
    }
    
    const handleChangFilterValue = (value: string) => {
        updateFilter({by: filter.by, type: filter.type, value: value})
    }

    const handleClickValue = () => {
        if(filter.type){
            if(action === 'openMultiDate'){
                setOpen(true);
            }
        }
    }
    
    const delayDebounceFn = useRef<NodeJS.Timeout | null>(null);

    const handleChangeText = (value: string) => {
        if(action !== 'text')return 
        setDisplayValueField(value)
        if (delayDebounceFn.current) {
            clearTimeout(delayDebounceFn.current);
        }
        delayDebounceFn.current = setTimeout(() => {
            updateFilter({by: filter.by, type: filter.type, value: value})
        }, 500);
    };

    useEffect(() => {
        setDisplayValueField(filter.value)
    },[filter.value])

    const getCurrentFilterType = () => {
        return transformSchemaTypeToApiFilters(pageConfig.schemas.find(sch => sch.ref === filter.by)?.type)
    }

    return (
        <Box className='w-full ' sx={{ width:652, display: 'flex', gap: 2, justify: 'center', alignItems: 'center' }}>
            <FormControl size="sm" className="w-1/3">
                {first && <FormLabel> Filtrer par </FormLabel>}
                <Select
                    size="sm"
                    placeholder="---" 
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={filter.by}
                >
                    <Option onClick={() => handleChangeFilterBy('')} value=''>---</Option>
                    {getSelectItemList(pageConfig.schemas, true).map((selectItem: SelectItem, index) => (
                        <Option onClick={() => handleChangeFilterBy(selectItem.ref)} key={index} value={selectItem.ref}>
                            {renderFilterIcon(selectItem.type)}
                            {selectItem.title}
                        </Option>
                    ))}
                </Select>
            </FormControl>

            <FormControl size="sm"  className="w-1/3">
                {first && <FormLabel> Filtre </FormLabel>}
                <Select
                    disabled={filter.by === ''}
                    size="sm"
                    placeholder="---"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={filter.type}
                >
                    <Option value=''>---</Option>
                    
                    { getCurrentFilterType() === 'date'  && filterDateList.map((filter, index) => (
                        <Option onClick={() => handleChangFilterType(filter)} key={index} value={filter.value}>
                            {filter.icon && <ListItemDecorator>
                                <AppIcon solid icon={filter.icon} />
                            </ListItemDecorator>}
                            {filter.title}
                        </Option>
                    ))}
                    { getCurrentFilterType() === 'text'  && filterTextList.map((filter, index) => (
                        <Option onClick={() => handleChangFilterType(filter)} key={index} value={filter.value}>
                            {filter.icon && <ListItemDecorator>
                                <AppIcon solid icon={filter.icon} />
                            </ListItemDecorator>}
                            {filter.title}
                        </Option>
                    ))}

                    { getCurrentFilterType() === 'amount'  && filterAmountList.map((filter, index) => (
                        <Option onClick={() => handleChangFilterType(filter)} key={index} value={filter.value}>
                            {filter.icon && <ListItemDecorator>
                                <AppIcon solid icon={filter.icon} />
                            </ListItemDecorator>}
                            {filter.title}
                        </Option>
                    ))}

                    { getCurrentFilterType() === 'boolean'  && filterBooleanList.map((filter, index) => (
                        <Option onClick={() => handleChangFilterType(filter)} key={index} value={filter.value}>
                            {filter.icon && <ListItemDecorator>
                                <AppIcon solid icon={filter.icon} />
                            </ListItemDecorator>}
                            {filter.title}
                        </Option>
                    ))}
                    { getCurrentFilterType() === 'select'  && 
                        pageConfig.schemas.find((sch) => sch.ref === filter.by)?.valueMap?.map((filter, index) => (
                        <Option onClick={() => handleChangFilterType({title: '', value: filter.value}, true)} key={index} value={filter.value}>
                            {filter.icon && <ListItemDecorator>
                                <AppIcon solid icon={filter.icon} />
                            </ListItemDecorator>}
                            {filter.value}
                        </Option>
                    ))}

                </Select>
            </FormControl>

            <FormControl size="sm"  className="w-1/3">
                {first && <FormLabel> Valeur </FormLabel>}
                <Input
                    defaultValue={filter.value} 
                    value={filter.type === 'start_end' ? filter.value : displayValueField}
                    size="sm"
                    placeholder='---'
                    onClick={handleClickValue}
                    disabled={filterDisableAction.includes(filter.type)}
                    onChange={(e) => handleChangeText(e.target.value)}
                />
            </FormControl>
            <FormControl size="sm" >
                {first && <FormLabel>ㅤ</FormLabel>}
                <IconButton className="pt-8" size='sm'  variant="soft" color="danger" onClick={removeFilter}>
                    <AppIcon icon='TrashIcon' solid/>
                </IconButton>
            </FormControl>
            
            <AppDatePickerRangeModal open={open} onClose={() => setOpen(false)} handleChange={(value) => handleChangFilterValue(value)} />
        </Box>
    )
}

export default FilterItem 
import { ColorPaletteProp } from '@mui/joy/styles';
import { Chip } from '@mui/joy';
import { IconName, AppIcon } from '@app/components/AppIcon';


export interface Props {
    color: string;
    title: string;
    icon?: IconName
    iconEnd?: boolean;
    handleClick?: () => void;
    solid?: boolean;
    selected?: boolean;
}

export const StatusChip = ({ color, title, icon, iconEnd = false, handleClick, solid = true, selected = false}: Props) => {
    return <Chip
        variant={selected ? "solid" : "soft"}
        size="sm"
        
        className={`${handleClick && 'hover:underline'}`}
        startDecorator={icon && !iconEnd && <AppIcon  icon={icon} solid={solid} />}
        endDecorator={icon && iconEnd && <div className={title === "" ? "-ml-1" : ''}><AppIcon icon={icon} solid={solid} /></div>}
        onClick={handleClick && handleClick}
        color={{
            green: 'success',
            gray: 'neutral',
            red: 'danger',
            blue: 'primary',
            yellow: 'warning',
            white: 'info',
        }[color] as ColorPaletteProp}
    >
        {title}
    </Chip>;
};

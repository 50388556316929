import { PageConfig } from '../type';

export const reconductionsPourImpressionSchema: PageConfig = {
  config: {
    modelName: 'reconductions Pour Impression',
    itemName: 'une reconductions',
    apiUrl: '/view-parrainages',
    options: ['filter', 'order'],
    banExport: ['pdf'],
    // defaultOrder: { ['facture/numeroFacture']: 'asc' },
    // secondarySearch: 'Numero Facture',
    // redirectTo: '/parrainages',
  },
  // entreprise	téléphone	libellé facture	coordonnées correspondance	accord de	date accord	date storno	email	info interne	publicité	reconduction	annonce ok	CHF promesse	id club	nom club	nom collaborateur	NPA

  schemas: [
    {
      style: { width: 200 },
      ref: 'entreprise',
      type: 'pargraphe',
      search: true,
    },
    { style: { width: 140 }, ref: 'telephone', ban: ['order', 'filter'] },
    { style: { width: 140 }, ref: 'telephone2', ban: ['order', 'filter'] },
    { style: { width: 240 }, ref: 'libelleFacture', search: true },
    { style: { width: 200 }, ref: 'coordonneesCorrespondance' },
    { style: { width: 140 }, ref: 'accordDe', ban: ['order', 'filter'] },
    { style: { width: 140 }, ref: 'dateAccord', type: 'date' },
    { style: { width: 140 }, ref: 'dateStorno', type: 'date' },
    { style: { width: 280 }, ref: 'email', ban: ['order', 'filter'] },
    {
      style: { width: 240 },
      ref: 'noteInterne',
      title: 'Info Interne',
      type: 'HTML',
    },
    { style: { width: 140 }, ref: 'publicite' },
    { style: { width: 140 }, ref: 'reconduction', type: 'bool' },
    { style: { width: 140 }, ref: 'annonceOk', type: 'bool' },
    {
      style: { width: 140 },
      ref: 'montantPromesse',
      type: 'money',
      title: 'Montant promesse',
      search: true,
    },
    { style: { width: 140 }, ref: 'mandat/ref', title: 'Id Club' },
    { style: { width: 140 }, ref: 'mandat/club/nom', title: 'Nom Club' },
    {
      style: { width: 140 },
      ref: 'collaborateur/ref',
      title: 'Id Collaborateur',
    },
    { style: { width: 140 }, ref: 'personneMorale/adresse/NPA', title: 'Npa' },
  ],
};

export default reconductionsPourImpressionSchema;

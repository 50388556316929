import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { FilterQuery } from '@app/api/apiType';
import { es } from 'date-fns/locale';

type FilterItem = {
    key: 'filter_by' | 'filter_type' | 'filter_value',
    value: string
}

export const useAppNavigation = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();

    const getPathSegments = () => {
        const pathSegments = location.pathname.split('/tables/')[1];
        return pathSegments ? pathSegments.split('?')[0] : '';
    }

    const getBaseApp = () => {
        return location.pathname.split('/')[2];
    }

    const getCurrentTable = (): string => {
        const currentPath = getPathSegments().split('/');
        return currentPath[currentPath.length - 1]
    };

    const getFilterTable = (): string => {
        const currentPath = getPathSegments().split('/');
        return currentPath[currentPath.length - 3]
    };

    const getFilterTableId = (): string => {
        const currentPath = getPathSegments().split('/');
        return currentPath[currentPath.length - 2]
    }

    const getParams = (key: string): string => {
        return searchParams.get(key) || ''
    }

    const setParams = (key: string, value: string) => {
        searchParams.set(key, value)
        setSearchParams(searchParams);
    }

    const resteParams = (): void => {
        navigate({ search: '' });
    }

    const checkReturnFilterTableId = (tableName: string): string | null => {
        return getFilterTable() === tableName ? getFilterTableId() : null
    }

    const navigateToRelation = (tableToNavigate: string, id: number, from?: string): void => {
        
        const localBaseApp = location.pathname.split('/')
        let fromUrl = from ? `${localBaseApp[0]}/${localBaseApp[1]}/${localBaseApp[2]}/${from}` : location.pathname
        const newUrl = `${fromUrl}/${id}/${tableToNavigate}`;
        window.open(newUrl, '_blank');
    };



    return { 
        getCurrentTable, 
        getFilterTable, 
        getFilterTableId, 
        navigateToRelation, 
        getParams,
        setParams,
        resteParams,
        checkReturnFilterTableId,
        getBaseApp,
    }
}



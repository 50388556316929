import { PageConfig } from '../type';

export const clubSchema: PageConfig = {
  config: {
    modelName: 'Clubs',
    itemName: 'un club',
    apiUrl: '/clubs',
    options: ['create', 'update', 'updateMany', 'delete', 'filter', 'order'],
  },
  schemas: [
    {
      style: { width: 140 },
      ref: 'sport/nom',
      search: true,
      form: {
        requier: true,
        select: {
          apiUrl: '/sports',
          fieldName: 'nom',
          fieldResult: 'nom',
          type: 'select',
        },
      },
      ban: ['order', 'filter'],
    },
    {
      style: { width: 140 },
      ref: 'nom',
      search: true,
      form: { requier: true },
    },
    {
      style: { width: 140 },
      ref: 'adresse/region',
      ban: ['order', 'filter'],
      search: true,
      title: 'Canton',
      form: { requier: true },
    },
    {
      style: { width: 140 },
      ban: ['order', 'filter'],
      ref: 'adresse/pays',
      title: 'Pays',
      valueMap: [
        { value: 'CH', color: 'gray' },
        { value: 'FR', color: 'gray' },
      ],
      form: { requier: true },
    },
    {
      style: { width: 140 },
      ref: 'mandat',
      title: 'mandats',
      type: 'relation',
      redirect: {
        itemName: 'mandat',
        to: 'mandats',
      },
      ban: ['order', 'filter'],
    },
  ],
};

import { StatusChip } from "./StatusChip"
import { PageConfig } from "@app/pages";
import AppDrawer from "@app/components/AppDrawer";
import { useState } from "react";
import { OrderTable } from "./OrderTable";
import { TableHeaderLayout } from "../header/TableHeaderLayout";

interface Props {
    data: [][]
    pageConfig: PageConfig
    title: string
    amount: number
    onSuccess: () => void,
}

export const SubDrawerBtn = ({ data, pageConfig, title, amount, onSuccess }: Props) => {

    const [open, setOpen] = useState(false)

    return (
        <>
            <StatusChip color={amount === 0 ? 'green' : 'blue'} title={title} selected={open} icon='ListBulletIcon' iconEnd handleClick={() => setOpen(true)} />
            <AppDrawer onClose={() => setOpen(false)} title={pageConfig.config.modelName} open={open}>
                <TableHeaderLayout>
                    <OrderTable 
                        onSuccess={onSuccess}
                        pageConfig={pageConfig}
                        data={data}
                        isLoaded 
                        isSub={true}
                    />
                </TableHeaderLayout>
            </AppDrawer>
        </>
    )
} 
import * as React from 'react';
import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';
import AppDatePicker from './AppDatePicker';
import { AppModal } from './AppModal';

interface prop {
    open: boolean;
    onClose: () => void;
    handleChange: (arg1: string) => void
}

export default function AppDatePickerRangeModal({ open, onClose, handleChange }: prop) {

    const [selectedStart, setSelectedStart] = React.useState<Date>();
    const [selectedEnd, setSelectedEnd] = React.useState<Date>();

    const startDate = selectedStart ? format(selectedStart, 'P') : '...'
    const endDate = selectedEnd ? format(selectedEnd, 'P') : '...'

    const submitDateChange = () => {
        const date = startDate + "-" + endDate;
        handleChange(date);
        onClose();
    }

    return (
        <AppModal disabelSubmit={startDate === '...' || endDate === '...'} icon='CalendarDaysIcon' title="Date" open={open} onClose={onClose} handleSubmit={submitDateChange} >
            <div className='flex space-x-8'>
                <div>
                    <p>Du {startDate}.</p>
                    <AppDatePicker selected={selectedStart} setSelected={setSelectedStart} />
                </div>
                <div>
                    <p>Au {endDate}.</p>
                    <AppDatePicker selected={selectedEnd} setSelected={setSelectedEnd} />
                </div>
            </div>
        </AppModal>

    );
}
import AddCommandeClub from "@app/main/forms/AddCommandeClub";
import RequestField from "@app/main/forms/components/RequestField";
import { PageConfig } from "@app/pages";
import { useAppFiltersUrl } from "@app/system/hooks/useAppFiltersUrl";
import { FormControl, FormLabel, Option, Select } from "@mui/joy";
import { useEffect, useState } from "react";

type props = {
    onSuccess: () => void;
}

const CustomCommandeClub = ({onSuccess}: props) => {
    const {autoUpdateFilter} = useAppFiltersUrl()
    const [selectedFournisseur, setSelectedFournisseur] = useState<string>()
    const [selectedMandat, setSelectedMandat] = useState<string>()

   useEffect(() => {
       if(selectedFournisseur !== null && selectedFournisseur !== undefined){
            autoUpdateFilter(
                {   
                    filter_by: 'fournisseur/id',
                    filter_type: 'equal',
                    filter_value: selectedFournisseur.toString(),
                }, 
            )
       }
       if(selectedMandat !== null && selectedMandat !== undefined){
            autoUpdateFilter(
                {   
                    filter_by: 'mandat/id',
                    filter_type: 'equal',
                    filter_value: selectedMandat.toString(),
                }, 
            )
       }
    }, [selectedFournisseur, selectedMandat])

    return (
        <>
            <FormControl size="sm">
                <FormLabel>Fournisseur</FormLabel>
                <RequestField apiUrl='/fournisseurs' keyRelation='nom' keyResult='id' type='select' onChange={(e) => setSelectedFournisseur(e)} value={selectedFournisseur ? selectedFournisseur : ''} />
            </FormControl>
            <FormControl size="sm">
                <FormLabel>Mandat/ref</FormLabel>
                <RequestField apiUrl='/mandats' keyRelation='ref' keyResult='id' type='autocomplete' onChange={(e) => setSelectedMandat(e)} value={selectedMandat ? selectedMandat : ''} />
            </FormControl>
            <AddCommandeClub onSuccess={onSuccess} />

        </>
    );
};

export default CustomCommandeClub;
import { useEffect, useState } from 'react';
import AppLoading from './app/components/AppLoading';
import RoutesConfig from './app/config/routes.config';
function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <div className='flex h-screen w-full items-center justify-center '>
      <AppLoading />
    </div>
  ) : (
    <RoutesConfig />
  );
}

export default App;

import { ReactNode } from 'react'
import { Box } from '@mui/joy';

interface Props {
    children: ReactNode;
}

export const TableHeaderLayout = ({ children }: Props) => {
    return (
        <Box
            className="SearchAndFilters-tabletUp"
            sx={{
                borderRadius: 'sm',
                py: 2,
                display: {
                    xs: 'none',
                    sm: 'flex',
                },
                flexWrap: 'wrap',
                gap: 1.5,
                '& > *': {
                    minWidth: {
                        xs: '120px',
                        md: '160px',
                    },
                },
            }}
        >
            {children}
        </Box>
    )
}
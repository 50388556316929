import { Schema } from "@app/pages"
import { camelCaseToText } from "../utils/basic";
import { ListItemDecorator } from "@mui/joy"; // Add this import statement

// icons
import LooksOneIcon from '@mui/icons-material/LooksOne';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { pageFilterBanList } from "@app/pages";

export interface SelectItem {
    ref: string;    
    title:  string;
    type: Schema['type'];
    choice?: string[]; 
    basicType: SelectType
}

export type SelectType = 'amount' | 'date' | 'boolean' | 'text' | 'select' 

export const useAppSchema = () => {

    const getSelectItemList = (schemas: Schema[], filter: boolean = false): SelectItem[] => {
        let schemasList: SelectItem[] = [];
        schemas.forEach((schema: Schema) => 
        {
            const basicType = transformSchemaTypeToApiFilters(schema.type)
            if((filter ? !schema.ban?.includes('filter') : !schema.ban?.includes('order')) && pageFilterBanList.includes(schema.type) === false){
                schemasList.push({
                    ref: schema.ref, 
                    title: getSchemaTitle(schema), 
                    type: schema.type, basicType: basicType, 
                    choice: basicType === 'select' ? schema.valueMap?.map((item) => item.value) : undefined
                })
            }   
        })
        // sort by title
        schemasList.sort((a, b) => a.title.localeCompare(b.title)).reverse()
        schemasList.sort((a, b) => a.basicType?.localeCompare(b.basicType ?? '') ?? 0).reverse()
        return schemasList
    }

    const getSearchString = (schemas: Schema[]): string => {
        let schemasList: string[] = [];
        schemas.forEach((schema: Schema) => 
        {
            if(schema.search){
                schemasList.push(getSchemaTitle(schema))
            }
        })
        return schemasList.join(', ')
    }

    const getSchemaTitle = (schema: Schema): string => {
        return schema.title ? schema.title : camelCaseToText(schema.ref) 
    }

    const getData = (data: any, ref: string) => {
        const path = ref?.split('/') ?? [];
        path.forEach((item: string) => {
            data = data && data[item]
        })
        return data
    }

    const transformSchemaTypeToApiFilters = (type: Schema['type']) => {
        if (type === 'money' || type === 'amount' || type === 'percent') {
            return 'amount'
        } else if (type === 'date' || type === 'date-full') {
            return 'date'
        } else if (type === 'bool') {
            return 'boolean'
        } else if(type === 'select'){
            return 'select'
        } else {
            return 'text'
        }
    }

    const renderFilterIcon = (type: Schema['type']) => {
        const ListIcon: Record<SelectType, JSX.Element> = {
            "amount": <LooksOneIcon />,
            "date": <QueryBuilderIcon />,
            "boolean": <ToggleOnIcon />,
            "text": <SortByAlphaIcon />,
            "select": <FormatListBulletedIcon />,
        }
        return <ListItemDecorator>{ListIcon[transformSchemaTypeToApiFilters(type)]}</ListItemDecorator>
    }

    return { 
        getSelectItemList,
        getSearchString,
        getSchemaTitle,
        getData,
        transformSchemaTypeToApiFilters,
        renderFilterIcon,
    }
}
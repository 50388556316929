import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    // "ngrok-skip-browser-warning": "69420",
  },
});

export const setToken = (token: string) => {
  const auth = `Bearer ${token}`;
  axiosInstance.defaults.headers.common['Authorization'] = auth;
};

axiosInstance.interceptors.response.use(
  function (response) {
    console.info('response->', response)
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401 && window.location.pathname !== '/') {
      sessionStorage.removeItem('access_token');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

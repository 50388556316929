import axios from '@app/config/axios.config';

type GetUsersParams = {
  page: number;
  limit: number;
  search: string;
  sort_by: string;
  asc: boolean;
};

export type User = {
  ref: string;
  prenom: string;
  nom: string;
  id: string;
  statut: string;
  dateEntree: Date;
  dateSortie: Date | null;
  notes: string | null;
};
export const UserRoles = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  COLLABORATEUR: 'COLLABORATEUR',
  // ... other roles ...
} as const;

export const getUsers = async (
  params: GetUsersParams,
): Promise<{ users: User[]; totalCount: number }> => {
  try {
    const res = await axios.get('/users', { params });
    return res.data;
  } catch (err) {
    console.error(err);
    return { users: [], totalCount: 0 };
  }
};

export const getUser = async (id: string) => {
  return await axios.get(`/users/${id}`);
};

export const createUser = async (data: {}) => {
  return await axios.post('/users', data);
};

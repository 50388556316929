import { AppIcon } from "@app/components/AppIcon"
import { AppModal } from "@app/components/AppModal"
import { FormLabel, FormControl, Button, Sheet, Divider } from "@mui/joy"
import { useEffect, useState } from "react"
import { PageConfig } from "@app/pages"
import { useAppFiltersUrl } from "@app/system/hooks/useAppFiltersUrl"
import { FilterItem } from "./FilterItem"
import { filterItemType } from "./FilterItem"
import { FilterQuery } from "@app/api/apiType"

interface FiltersProps {
    pageConfig: PageConfig
}



export const Filters = ({ pageConfig }: FiltersProps) => {
    const { getFilters, createFilter, deleteAllFilters } = useAppFiltersUrl()
    const filtersUrl = getFilters()
    // on utilise un state local pour verifier que le url est le meme que le state local 
    const [open, setOpen] = useState<boolean>(false)

    const [filterUrlCopy, setFilterUrlCopy] = useState<filterItemType[]>([]);
    const [filters, setFilters] = useState<filterItemType[]>([])
    
    const addNewFilter = () => {
        setFilters([...filters, {by: '', type: '', value: ''}])
    }

    const removeFilter = (index: number) => {
        const newFilters = filters.filter((_, i) => i !== index)
        setFilters(newFilters)
    }

    const updateFilter = (index: number, filter: filterItemType) => {
        const newFilters = filters.map((f, i) => {
            if(i === index){
                return filter
            }
            return f
        })
        setFilters(newFilters)
    }

    useEffect(() => {
        if(filterUrlCopy !== filters){
            setUrlToFilters()
        }
    }, [])

    const setUrlToFilters = () => {
        setFilters([])
        let filtersResult: filterItemType[] = []
        filtersUrl.forEach((filter) => {
            const filterQuery: filterItemType = {
                by: filter.filter_by,
                type: filter.filter_type,
                value: filter.filter_value
            }
            filtersResult.push(filterQuery)
        })
        setFilterUrlCopy(filtersResult)
        setFilters(filtersResult)
    }

    const setFiltersToUrl = () => {
        setFilterUrlCopy(filters)
        deleteAllFilters()
        filters.forEach((filter, index) => {
            const filterQuery: FilterQuery = {
                filter_by: filter.by,
                filter_type: filter.type,
                filter_value: filter.value
            }
            createFilter(filterQuery, index)
        })
    }


    const excludFilterList = ['select_month_metier']

    return (
        pageConfig.config.options.includes('filter') && <>
            <FormControl size="sm">
            <FormLabel>Filtres</FormLabel>
                <Button
                    size='sm'
                    variant="soft"
                    color="warning"
                    onClick={() => setOpen(true)}
                >
                    <AppIcon icon="AdjustmentsVerticalIcon" />
                    Filtrer
                </Button>
            </FormControl>

            
            
            <AppModal handleSubmit={() => {setFiltersToUrl(); setOpen(false)}} open={open} onClose={() => setOpen(false)} title='Filtrer' icon='AdjustmentsVerticalIcon'> 
                <Sheet className="rounded-md space-y-4 flex flex-col justify-center items-center" variant="plain" color="neutral" sx={{ p: 4 }}>
                    {filters.length === 0 && <div className="my-4 mx-48">Aucun filtres</div>}
                    {filters.map((filter, index) => (
                        !excludFilterList.includes(filter.type) && <FilterItem 
                            first={index === 0}
                            key={index}
                            filter={filter}
                            removeFilter={() => removeFilter(index)}
                            updateFilter={(filter: filterItemType) => updateFilter(index, filter)}
                            pageConfig={pageConfig} 
                        />
                    ))
                    }
                    <Divider />
                    <div className="w-full flex justify-end">
                        <Button size='sm' variant="soft" color="primary" 
                            onClick={() => {addNewFilter()}}
                            startDecorator={<AppIcon icon="PlusIcon" />} 
                        > 
                                Ajouter un filtre
                        </Button> 
                    </div>
                </Sheet>
                    
                
               
            </AppModal>
        </>
       
    )
}



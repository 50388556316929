import { personnePhysiqueSchemaVersionBtn } from '../subSchemas/personnePhysiqueVersionBtn';
import { PageConfig } from '../type';

export const personnePhysiqueSchema: PageConfig = {
  config: {
    modelName: 'Personne physique',
    itemName: 'une personne physique',
    apiUrl: '/personnesPhysiques',
    options: [
      'create',
      'update',
      'updateMany',
      'delete',
      'filter',
      'order',
      'deleteMany',
      'createNewVersion',
      'newVersion',
    ],
  },

  relations: [
    {
      labelName: 'Personne Morale',
      getKeyInData: 'personneMorale',
      postKeyDb: 'personneMorale',
      getApiUrlOptions: '/personnesMorales',
      displayFieldOption: [
        'raisonSociale',
        'adresse/ligne1',
        'adresse/region',
        'adresse/casePostal',
        'adresse/NPA',
        'telephone',
        'email',
      ],
      asVersion: true,
      many: true,
    },
  ],

  schemas: [
    {
      style: { width: 120 },
      ref: 'customFacturesPersonnePhysique',
      title: 'Numero facture',
      type: 'custom',
      form: { display: false },
    },
    // {
    //   style: { width: 300 },
    //   ref: 'personneMorale/raisonSociale',
    //   form: {
    //     requier: true,
    //     select: {
    //       apiUrl: '/personnesMorales',
    //       fieldName: 'raisonSociale',
    //       fieldResult: 'id',
    //       type: 'autocomplete',
    //     },
    //   },
    // },
    {
      style: { width: 140 },
      ref: 'titre',
      type: 'select',
      valueMap: [
        { value: 'Monsieur', color: 'gray', icon: 'UserIcon' },
        { value: 'Madame', color: 'gray', icon: 'UserIcon' },
      ],
    },
    {
      style: { width: 140 },
      ref: 'nom',
      search: true,
      form: { requier: true },
    },
    {
      style: { width: 140 },
      ref: 'prenom',
      search: true,
      form: { requier: true },
    },
    {
      style: { width: 220 },
      ref: 'personneMorale',
      type: 'subSchema',
      subSchema: {
        display: 'table',
        multi: false,
        itemName: 'personne morale',
        config: {
          modelName: 'personneMorale',
          itemName: 'un personne morale',
          apiUrl: '/personnesMorales',
          options: ['create', 'add', 'update', 'delete'],
        },
        schemas: [
          { style: { width: 240 }, ref: 'raisonSociale' },
          {
            style: { width: 160 },
            ref: 'adresse/ligne1',
            // type: 'simpleRelation',
            // simpleRelation: { dbName: 'adresse', subRef: 'ligne1'},
          },
          { style: { width: 160 }, ref: 'telephone' },
        ],
      },
    },
    { style: { width: 140 }, ref: 'telephone' },
    { style: { width: 180 }, ref: 'email' },
    { style: { width: 140 }, ref: 'notes', type: 'note' },
    {
      style: { width: 100 },
      ref: 'status',

      type: 'select',
      valueMap: [
        { value: 'actif', color: 'green', icon: 'CheckIcon' },
        { value: 'inactif', color: 'red', icon: 'XMarkIcon' },
      ],
    },
    {
      style: { width: 140 },
      ref: 'role',
      type: 'select',
      valueMap: [
        { value: 'decideur', color: 'yellow' },
        { value: 'patron', color: 'blue' },
      ],
    },
    {
      style: { width: 140 },
      ref: 'version',
      type: 'amount',
      ban: ['update', 'create', 'updateMany'],
    },
    personnePhysiqueSchemaVersionBtn,
  ],
};

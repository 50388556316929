import { useState } from 'react';
import AppAlert, { MessageAlert } from "./AppAlert";
import AppLoading from "./AppLoading";

interface Props {
    loading: boolean;
    message: MessageAlert | null;
    acceptFile: string;
    file: File | null;
    onFileUpload: (file: File) => void;
}

const AppDropZone = ({ loading, message, acceptFile, file, onFileUpload }: Props) => {
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFile = e.target.files[0];
            onFileUpload(selectedFile);
        }
    };

    return (
        <div className="max-w-2xl min-w-[700px] mx-auto">
            {loading ? (
                <AppLoading />
            ) : (
                <div className="flex items-center justify-center w-full">
                    {message ? (
                        <AppAlert message={message} />
                    ) : (
                        <label className="flex flex-col items-center justify-center w-full h-64 border-2 scale-90 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click</span> ou drag and drop</p>
                                <div>{file && `${file.name} - ${file.type}`}</div>
                            </div>
                            <input id="dropzone-file" type="file" className="hidden" accept={acceptFile} onChange={handleFileChange} />
                        </label>
                    )}
                </div>
            )}
        </div>
    );
};

export default AppDropZone;
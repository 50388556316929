import { useAppFiltersUrl } from "@app/system/hooks/useAppFiltersUrl";
import { FormControl, FormLabel } from "@mui/joy"
import { useEffect, useState } from "react";
import RequestField from "@app/main/forms/components/RequestField";
type props = {
    filterBy: string
}


const FilterCollaborateur = ({filterBy}:props) => {

    const {autoUpdateFilter} = useAppFiltersUrl()
    const [selectedCollaborateur, setSelectedCollaborateur] = useState<string>('')

    useEffect(() => {
        if(selectedCollaborateur !== null && selectedCollaborateur !== undefined){
            autoUpdateFilter(
                 {   
                     filter_by: filterBy,
                     filter_type: 'equal',
                     filter_value: selectedCollaborateur,
                 }, 
             )
        }
    }, [selectedCollaborateur])

    return (
        <>
            <FormControl size="sm">
                <FormLabel>Collaborateur</FormLabel>
                <RequestField apiUrl='/users' keyRelation='ref' keyResult='id' type='select' onChange={(e) => setSelectedCollaborateur(e)} value={selectedCollaborateur ? selectedCollaborateur : ''} />
            </FormControl>
        </>
    )
}

export default FilterCollaborateur
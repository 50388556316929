import { Schema } from "@app/pages";
import { Switch, Textarea, Input, FormLabel, FormControl, Typography, Select, Option } from '@mui/joy';
import { AppIcon } from '@app/components/AppIcon';
import { DataItem } from "@app/system/hooks/useAppForm";
import RequestField from "./RequestField";
import SearchAutocomplete from "./SearchAutocomplete";

interface Props {
  dataItem: DataItem
  updateValue: (index: number, value: string) => void
  index: number
  width?: number
  displayLable?: boolean
  disabled?: boolean
}

export const RenderField = ({dataItem, updateValue, index, width, displayLable = true, disabled = false}: Props) => {

    const typeIcon: any = {
      money: 'CircleStackIcon',
      phone: 'PhoneIcon',
      percent: 'ReceiptPercentIcon',
      amount: 'VariableIcon',
    }
    const renderInput = (type: Schema['type']) => {
      const value: any = dataItem.value
      if(type === 'bool'){
        return <Switch disabled={dataItem.disabled} checked={value === true ? true : false} onClick={() => changeData(!value)} className="mt-1" size="sm" variant="soft" />
      }else if(type === 'amount' || type === 'percent' || type === 'money'){
        return <Input disabled={dataItem.disabled} value={value} onChange={(e) => changeData(e.target.value)} endDecorator={<AppIcon size='sm' icon={typeIcon[type]} />} type="number"/>
      } else if(type === 'date' || type === 'date-full') {
        return <Input disabled={dataItem.disabled} value={value} onChange={(e) => changeData(e.target.value)} type='date'/>
      }else if(type === "phone"){
        return <Input disabled={dataItem.disabled} value={value} onChange={(e) => changeData(e.target.value)} endDecorator={<AppIcon size='sm' icon='PhoneIcon' />}/>
      }else if(type === 'note' || type === "HTML" || type === "HTMLText" || type === "pargraphe2" || type === "pargraphe"){
        return <Textarea disabled={dataItem.disabled} value={value} onChange={(e) => changeData(e.target.value)} minRows={2} />
      }else if(type === 'select'){
        return <Select disabled={dataItem.disabled} value={value}  >
                {dataItem.options?.map((option, index: number) => (
                  <Option key={index} onClick={() => changeData(option)} value={option}>{option}</Option>
                )) }
              </Select>
      }else if(dataItem.formRelation){
        if(dataItem.formRelation.type === 'search'){
          return <SearchAutocomplete apiUrl={dataItem.formRelation.apiUrl} keyDisplay={dataItem.formRelation.fieldName} onChange={(e) => changeData(e)} initialId={value} />
        }else{
          return <RequestField apiUrl={dataItem.formRelation.apiUrl} keyRelation={dataItem.formRelation.fieldName} keyResult={dataItem.formRelation.fieldResult} type={dataItem.formRelation.type} onChange={(e) => changeData(e)} value={value}  />
        }
      }else{
        return <Input disabled={dataItem.disabled} value={value} onChange={(e) => changeData(e.target.value)}/>
      }
    } 

    const displayError = () => {

      if(dataItem.error === 'requier'){
        return <Typography fontSize='xs' color="danger"> (Ce champ est requis) </Typography>
      }
    }

    const changeData = (value: any) => {
      updateValue(index, value)
    }

    return (
      <>
        <FormControl color={dataItem.type !== "bool" ? dataItem.edited ? 'success' : 'neutral' : undefined} className="my-4 " size="sm">
          {displayLable && 
            <FormLabel >
              {dataItem.label} 
              {displayError()}
              {dataItem.require && <Typography fontSize='sm' color="danger">*</Typography>}
            </FormLabel>
          }
          <div className={width ? 'w-' + width.toString() : ''}>{renderInput(dataItem.type)}</div>
        </FormControl>
      </>
    );
} 

export default RenderField
import { PageConfig } from "../../pages/type";
import AssignListeNoire from "./Custom/AssignListeNoire";
import ValidateListeNoire from "./Custom/ValidateListNoire";

type props = {
    onSuccess: () => void,
    pageConfig: PageConfig,
    data: any,
    open: boolean,
    onClose: () => void,
} 

export const UpdateFormCustom = ({pageConfig, data, onSuccess, open, onClose }: props) => {
    return <>
        {pageConfig.config.indentifier === 'listeNoireViewSchema' && (    
            <AssignListeNoire pageConfig={pageConfig} idToUpdate={[data.id]} onSuccess={onSuccess} open={open} onClose={onClose}  />
        )}
        {pageConfig.config.indentifier === 'listeNoireUserAdminSchema' && (    
            <ValidateListeNoire idToUpdate={[data.id]} onSuccess={onSuccess} open={open} onClose={onClose} />
        )}
    </>
}
export default UpdateFormCustom;



import { Form, Schema, FormSelect } from "@app/pages";
import { useAppSchema } from "./useAppSchema";
import { parseISO } from 'date-fns'

export interface DataItem {
    initValue?: string|number|boolean
    type: Schema['type']
    value: string|number|boolean;
    label: string;
    ref: string;
    require: boolean;
    error: string|'requier'|'bad value';
    edited: boolean;
    options?: string[]
    disabled: boolean
    formRelation?: FormSelect
    canBeEmpty: boolean;
}

export const useAppForm = () => {
    const { getSchemaTitle, getData } = useAppSchema()
    const rejectedTypeList: Schema['type'][] = ['recursive', 'relation', 'subSchema']

    const createEmptyFormData = (schemas: Schema[]): DataItem[] => {
        return createFormData(schemas)
    }

    const createFormData = (schemas: Schema[], data?: any): DataItem[] => {
        const createMode =  data ? false : true
        const formData: DataItem[] = []
        schemas.forEach((schema) => {
            if(rejectedTypeList.includes(schema.type)){return}
            if(schema.form && schema.form.custom){return}
            if(schema.form && schema.form.display === false){return}
            if(createMode && schema.ban?.includes('create') ){return}
            if(!createMode && schema.ban?.includes('update') ){return}

            let refValue: Schema['ref'] = schema.ref;
            let schemaType: Schema['type'] = schema.type;
            let valueField = !createMode && cleanValue(getData(data, schema.ref),schema.type) || '';
            let options;

            if(schema.valueMap){
                options = schema.valueMap.map(option => option.value)
                schemaType = 'select'
            }

            if(createMode && schema.form && schema.form.defaultValue ){
                valueField = schema.form.defaultValue
            }

            const formDataItem: DataItem = {
                initValue: valueField,
                type: schemaType,
                value: valueField,
                label: getSchemaTitle(schema), 
                ref: refValue, 
                require: !data ? schema.form && schema.form.requier || false : false,
                error:"",
                edited: false,
                options: options,
                formRelation: schema.form && schema.form.select && schema.form.select,
                disabled: false,
                canBeEmpty: schema.form && schema.form.canBeEmpty || true,
            }
            formData.push(formDataItem)
        })
         
        return checkLinkFormDataField(formData, 'parrainage')
    }

    // check for display field
    const checkLinkFormDataField = (formData: DataItem[], apiUrl: string) => {
        if(apiUrl === 'parrainage'){
            const dateAnnulation = formData.find(element => element.ref === 'dateAnnulation')
            if(formData.find(element => element.ref === 'annulation')?.value === true){
                if(dateAnnulation)dateAnnulation.disabled = false
            }else{
                if(dateAnnulation)dateAnnulation.disabled = true
            }
            const dateStorno = formData.find(element => element.ref === 'dateStorno')
            if(formData.find(element => element.ref === 'storno')?.value === true){
                if(dateStorno)dateStorno.disabled = false
            }else{
                if(dateStorno)dateStorno.disabled = true
            }
            const dateStornoReactive = formData.find(element => element.ref === 'dataStornoReactive')
            if(formData.find(element => element.ref === 'stornoReactive')?.value === true){
                if(dateStornoReactive)dateStornoReactive.disabled = false
            }else{
                if(dateStornoReactive)dateStornoReactive.disabled = true
            }
        }

        return formData
    }

    const cleanValue = (value: string, type: Schema['type']): string|number|boolean => {
        function cleanDate(date: string){
            const newArray = date.split('/');
            return `${newArray[2]}-${newArray[1]}-${newArray[0]}`
        }
        if(type === "date" || type === 'date-full'){
            if(!value){return ''}
            return cleanDate(parseISO(value).toLocaleString().split(' ')[0])
        }else if(type === 'amount' || type === 'money' || type === 'percent'){
            return parseFloat(value)
        }else if(type === 'bool'){
            return value ? true : false
        }else if(type === 'phone'){
            if(!value) return ''
            return value.replaceAll(' ', '')
        }else{
            return value
        }
    }

    const createDataReq = (formData: DataItem[], forUpdate: boolean = false) => {
        const result = {}
        formData.forEach(element => {
            if(!element.canBeEmpty && element.value === ''){return}
            if(!forUpdate && (element.value === '' || Number.isNaN(element.value))){return}
            if(forUpdate && !element.edited){console.log(' not edited element->', element); return}
            if(element.type === 'amount' || element.type === 'money' || element.type === 'percent'){
                element.value = parseFloat(element.value as string)
            }   
            const key = {[element.ref]: element.value};
            Object.assign(result, key)
        });
        return result
    }

    const checkFormDataValidation = (formData: DataItem[]) => {
        let isError = false;
        const newFormData = formData.map(element => {
            if((element.value === '' || element.value === undefined) && element.require){
                element.error = 'requier'
                isError = true
            }else{
                element.error = ''
            }
            return element
        })
        newFormData.filter
        return {newFormData, isError}
    }

    const resetFormError = (formData: DataItem[]) => {
        const newFormData = formData.map(element => {
            element.error = ''
            return element
        })
        return newFormData
    }

    return {createEmptyFormData, createFormData, createDataReq, checkFormDataValidation, resetFormError, checkLinkFormDataField}
} 
import { PageConfig } from '../type';

export const factureViewSchema: PageConfig = {
  config: {
    modelName: 'factures',
    itemName: 'une facture',
    apiUrl: '/view-factures',
    options: ['filter', 'order'],
    redirectTo: '/factures',
    defaultOrder: { numeroFacture: 'asc' },
    secondarySearch: 'Numero Facture',
  },
  schemas: [
    {
      style: { width: 200 },
      ref: 'parrainage/personneMorale/raisonSociale',
      title: 'Raison Sociale',
    },
    {
      style: { width: 200 },
      ref: 'libelleFacture',
      type: 'pargraphe2',
      search: true,
    },
    {
      style: { width: 200 },
      ref: 'parrainage/coordonneesCorrespondance',
      title: 'Coordonnees Correspondance',
    },
    { style: { width: 200 }, ref: 'accordDe', ban: ['order', 'filter'] },
    { style: { width: 200 }, ref: 'email', ban: ['order', 'filter'] },
    { style: { width: 200 }, ref: 'nbParrainage', type: 'amount' },
    {
      style: { width: 200 },
      ref: 'parrainage/mandat/ref',
      title: 'idClub',
      type: 'ref',
    },
    {
      style: { width: 200 },
      ref: 'parrainage/mandat/club/nom',
      title: 'Nom Club',
    },
    { style: { width: 200, fixed: true }, ref: 'numeroFacture', search: true },
    { style: { width: 200 }, ref: 'id', title: 'ref', type: 'ref' },
    { style: { width: 200 }, ref: 'montantPayeHorsTva', type: 'float' },
    { style: { width: 200 }, ref: 'datePaiement', type: 'date' },
    {
      style: { width: 200 },
      ref: 'parrainage/montantPromesse',
      title: 'Chf Promesse',
      type: 'float',
    },
    {
      style: { width: 200 },
      ref: 'montantTva',
      title: 'Chf Tva',
      type: 'float',
    },
    {
      style: { width: 200 },
      title: 'chfPromesseTva',
      ref: 'montantPromesseTva',

      type: 'float',
    },
    {
      style: { width: 200 },
      ref: 'montantInt',
      title: 'Chf Int',
      type: 'money',
    },
    {
      style: { width: 200 },
      ref: 'montantCent',
      title: 'Chf Cent',
      type: 'money',
    },
    {
      style: { width: 200 },
      ref: 'parrainage/dateAccord',
      title: 'Date Accord',
      type: 'date',
    },
    {
      style: { width: 200 },
      ref: 'dateCreation',
      title: 'Date Creation',
      type: 'date',
    },
    {
      style: { width: 200 },
      ref: 'noteAnnonce',
      title: 'Info Annonce',
      type: 'note',
    },
    { style: { width: 200 }, ref: 'anfChoixAnnonce' },
    {
      style: { width: 200 },
      ref: 'parrainage/noteInterne',
      title: 'Note Interne',
      type: 'note',
    },
    { style: { width: 200 }, ref: 'qrRef' },
    { style: { width: 200 }, ref: 'logo', type: 'bool' },
    {
      style: { width: 200 },
      ref: 'contractType',
      ban: ['filter'],
      type: 'select',
      valueMap: [
        { value: 'ACVF', color: 'red' },
        { value: 'Materiel', color: 'blue' },
      ],
    },
  ],
};

import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { setToken } from '../../config/axios.config';
import { jwtDecode } from 'jwt-decode';
import { Token, UserRoles } from '../types';


export interface TokenPayload {
  iat: number;    // issued at timestamp
  exp: number;    // expiration timestamp
  id: number;
  username: string;
  roles: UserRoles[];
}

type AuthContextType = {
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  getTokenInfo: () => TokenPayload | null;
  isGranted: (userRoles: UserRoles[]) => boolean | undefined;
};

const AuthContext = createContext<AuthContextType>({
  login: async () => { },
  logout: () => { },
  getTokenInfo: () => null,
  isGranted: () => undefined,
});

export const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();

  const login = async (username: string, password: string) => {
    try {
      const res = await axios.post('/auth/login', {
        username,
        password: password,
      });
      const accessToken = res.data.access_token;
      sessionStorage.setItem('access_token', accessToken);
      setToken(accessToken);
      navigate('/home');
      
    } catch (err: any) {
      if (err.response.status === 401)
        throw new Error('Invalid username or password');
    }
  };

  const logout = () => {
    sessionStorage.removeItem('access_token');
    navigate('/');
  };

  const getTokenInfo = (): TokenPayload | null => {
    const token = sessionStorage.getItem('access_token');
    if (!token) return null;
    return jwtDecode<TokenPayload>(token) as TokenPayload;
  };

  const isGranted = (userRoles: UserRoles[] | undefined): boolean|undefined => {
    if(!userRoles) return undefined;
    return getTokenInfo()?.roles.some(role => userRoles.includes(role));
  };

  const value = useMemo(
    () => ({
      login,
      logout,
      getTokenInfo,
    }),
    []
  );

  return <AuthContext.Provider value={{...value, isGranted}}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

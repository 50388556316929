import {
    FormControl, FormLabel,
    Divider, Menu, MenuButton,
    MenuItem, Dropdown, ListItemDecorator,
    IconButton
} from '@mui/joy';
import { AppIcon, IconName } from '@app/components/AppIcon';
import { exportCSVFile, exportJsonFile, exportXMLFile, exportPDFFile, exportZipFile, exportExcelFile } from '@app/system/utils/dataExporter'
import { useAppNavigation } from '@app/system/hooks/useAppNavigation';
import { Schema, PageConfig } from '@app/pages';
import { Fragment } from 'react';
import { ColorPaletteProp } from '@mui/joy';
import { capitalizeFirstLetter } from '@app/system/utils/basic';
import { exportParrainageCurrentMonth } from '@app/system/utils/pdfExporter';
import { DataExport } from '@app/pages';
import { useState } from 'react';
import UpdateMany from '../../../forms/UpdateMany';
import AppLoading from '@app/components/AppLoading';
import UpdateManyCustom from '@app/main/forms/UpdateManyCustom';

export interface ItemOption {
    icon: IconName,
    onClick: () => any,
    title: string,
    color?: ColorPaletteProp,
    divider?: boolean,
}

interface Props {
    pageConfig: PageConfig,
    totalCount: number,
    handleGetAllData: (e:null|number) => any,
    itemList?: ItemOption[],
    refresh: () => void,
    selected: string[]
    setSelected: (arg: string[]) => void,
    isDataLoading: boolean

}

export const Options = ({ pageConfig, totalCount, handleGetAllData, itemList, refresh, selected, setSelected, isDataLoading }: Props) => {
    const [openUpdateMany, setOpenUpdateMany] = useState<boolean>(false);
    const [openUpdateManyCustom, setOpenUpdateManyCustom] = useState<boolean>(false);
    const [archive, setArchive] = useState<boolean>(false);
    const schemas = pageConfig.schemas

    let modelName: string = pageConfig.config?.modelName
    const renameMap = [
        {old: 'reconductions A Bloquer Pour Assistant Potentiel', new: 'reconduction A Bloquer'},
        {old: 'reconductions Pour Assistant Potentiel', new: 'reconduction Pour Assistant'}
    ]
    const find = renameMap.find((element) => element.old === modelName)
    if(find){modelName = find.new}
    const tableName = capitalizeFirstLetter(modelName)

    const { resteParams, getParams } = useAppNavigation()

    const exportLimit = 10000

    const onSuccessPlusArchive = () => {
        if(archive){
            setSelected([])
        }
        refresh()
    }

    const onSuccessPlusResetSelected = () => {
        setSelected([])
        refresh()
    }

    const exportType: DataExport[] = ['csv', 'json', 'xml', 'pdf', 'zip', 'xlsx']

    const handleDefaultExportFile = async (type: DataExport) => {
        if(totalCount >= exportLimit) {
            const userConfirmed = window.confirm('Vous ne pouvez pas exporter plus de ' + exportLimit.toString() + ' éléments. Ajouter des filtres ou cliquez sur ok pour exporter quand même');
            if (!userConfirmed) {
                return;
            }
        }
        const data = await handleGetAllData(exportLimit)
        switch (type) {
            case 'csv':
                exportCSVFile(schemas, data, tableName)
                break;
            case 'json':
                exportJsonFile(schemas, data, tableName)
                break;
            case 'xml':
                exportXMLFile(schemas, data, tableName)
                break;
            case 'pdf':
                exportPDFFile(schemas, data, tableName)
                break;
            case 'zip':
                exportZipFile(schemas, data, tableName)
                break;
            case 'xlsx':
                exportExcelFile(schemas, data, tableName)
                break;
            default:
                break;
        }
    }
    
    const handlePdfExportParrainagesCurrentMonthClick = async () => {
        setIsLoading(true)
        const filter: any[] = getParams('filters') ? JSON.parse(getParams('filters')) : []
        await exportParrainageCurrentMonth(schemas, filter)
        setIsLoading(false)
    }

    const handleFactureCsvExportFile = async () => {
        const data = await handleGetAllData(exportLimit)
        const filterKey = ['ref', 'mandat/ref', 'marque/nom', 'article', 'quantite', 'couleur', 'notes', 'coutBrut', 'coutNet', 'dateCommande', 'destinataire']
        const filterSchema: Schema[] = schemas.filter((head: Schema) => filterKey.includes(head.ref))
        filterSchema.push({ref: 'fournisseur'})
        const filterTableData = await data.tableData.filter((element: any) => {  
            if(selected.includes(element.id)){
                element.fournisseur = element.fournisseur[0] ? element.fournisseur[0].nom : null  
                return element
            }
        })
        filterTableData.forEach((element: any) => {})
        data['tableData'] = filterTableData
        exportCSVFile(filterSchema, data, 'facture')
    }
    const [isLoading, setIsLoading] = useState<boolean>(false)

    return (
        <>
            <FormControl size="sm">
                <FormLabel>Options</FormLabel>
                <Dropdown>
                    <MenuButton
                        size='sm'
                        variant="soft"
                        color="neutral"
                    >
                        {isLoading ? <div className='mr-2'><AppLoading /></div> : <AppIcon icon="EllipsisHorizontalIcon" />}
                        Plus
                    </MenuButton>
                    <Menu size="sm" sx={{ minWidth: 140 }}>
                        
                        {/* // Modification groupé */}
                        {pageConfig.config.options.includes('updateMany') && selected.length >= 1 &&
                            <MenuItem color='primary' onClick={() => {setOpenUpdateMany(true);  setArchive(false)}}>
                                <ListItemDecorator>
                                    <AppIcon icon="PencilSquareIcon" />
                                </ListItemDecorator>
                                Action groupé
                            </MenuItem>
                        } 

                        {selected.length > 0 && pageConfig.config.options.includes('updateManyCustom') && (
                            <MenuItem color='primary' onClick={() => setOpenUpdateManyCustom(true)}>
                                <ListItemDecorator>
                                    <AppIcon icon="Cog6ToothIcon" />
                                </ListItemDecorator>
                                Action groupé
                            </MenuItem>
                        )}

                        
                        
                        {
                        pageConfig.config.options.includes('deleteMany') && selected.length >= 1 &&
                            <MenuItem color='danger' onClick={() => {setOpenUpdateMany(true); setArchive(true)}}>
                                <ListItemDecorator>
                                    <AppIcon icon="TrashIcon" />
                                </ListItemDecorator>
                                Archive groupé
                            </MenuItem>
                        }
                        <Divider />

                        {/* // Telechargement */}
                        {exportType.map((type: DataExport, index: number) => {
                            if(pageConfig.config.banExport?.includes(type)) return null
                            return (
                                <MenuItem key={index} onClick={() => handleDefaultExportFile(type)}>
                                    <ListItemDecorator>
                                        <AppIcon icon="DocumentArrowDownIcon" />
                                    </ListItemDecorator>
                                    Télécharger {type.toUpperCase()}
                                </MenuItem>
                            )
                        })}
                        
                        {/* // Special case */}
                        {pageConfig.config.apiUrl === '/commandes' && selected.length >= 1 &&
                            <MenuItem onClick={handleFactureCsvExportFile}>
                                <ListItemDecorator>
                                    <AppIcon icon="ListBulletIcon" />
                                </ListItemDecorator>
                                Export commande fournisseur 
                            </MenuItem>
                        }

                        {pageConfig.config.apiUrl === '/view-parrainages/currentMonth' &&
                            <MenuItem onClick={handlePdfExportParrainagesCurrentMonthClick}>
                                <ListItemDecorator>
                                    <AppIcon icon="ListBulletIcon" />
                                </ListItemDecorator>
                                Export ZIP/PDF 
                            </MenuItem>
                        }

                        {/* // Custome */}
                        {itemList && itemList.map((item, index) => (
                            <Fragment key={index}>
                                {item.divider && <Divider />}
                                <MenuItem color={item.color} onClick={item.onClick}>
                                    <ListItemDecorator>
                                        <AppIcon icon={item.icon} />
                                    </ListItemDecorator>
                                    {item.title}
                                </MenuItem>
                            </Fragment>
                        ))}

                        

                        <Divider />
                        {pageConfig.config.options.includes('filter') && <MenuItem onClick={resteParams} color="danger">
                            <ListItemDecorator>
                                <AppIcon icon="AdjustmentsVerticalIcon" />
                            </ListItemDecorator>
                            Réinitialiser les filtres
                        </MenuItem>}
                    </Menu>
                </Dropdown>

                <UpdateMany archive={archive} onSuccess={onSuccessPlusArchive} pageConfig={pageConfig} selected={selected} open={openUpdateMany} onClose={() => setOpenUpdateMany(false)} />
                <UpdateManyCustom pageConfig={pageConfig} selected={selected} onSuccess={onSuccessPlusResetSelected} open={openUpdateManyCustom} onClose={() => setOpenUpdateManyCustom(false)} />
                
            </FormControl>
            
            <IconButton variant='soft' size='sm' className='h-4 ' sx={{marginTop: "24px"}} onClick={refresh}>
                <div className={isDataLoading ? 'animate-spin' : ''}><AppIcon icon="ArrowPathIcon" /></div>
            </IconButton>
                
        </>
    )

};

export default Options;

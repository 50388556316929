import { Outlet, Navigate } from 'react-router-dom';
import { setToken } from '../../config/axios.config';

export const ProtectRoutes = () => {
  if (sessionStorage.getItem('access_token')) {
    setToken(sessionStorage.getItem('access_token') as string);
  }
  return sessionStorage.getItem('access_token') ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

import { PageConfig } from '../type';
import listeNoireUserSchema from './listNoire';

export const listeNoireUserAdminSchema: PageConfig = {
  config: {
    indentifier: 'listeNoireUserAdminSchema',
    modelName: 'listeNoires',
    itemName: 'une listeNoire',
    apiUrl: '/liste-noire',
    options: ['filter', 'order', 'update', 'updateCustom', 'updateManyCustom'],
    banExport: ['pdf'],
    formInfos: [
      { ref: 'parrainage/facture/numeroFacture', title: 'Numeros de facture' },
      { ref: 'parrainageView/entreprise', title: 'Entreprise' },
      { ref: 'parrainageView/nom', title: 'Nom' },
      { ref: 'parrainageView/prenom', title: 'Prenom' },
    ],
  },

  schemas: [
    {
      type: 'date',
      style: { width: 140 },
      ref: 'createdAt',
      title: 'Date de création',
      ban: ['update'],
    },
    {
      type: 'date',
      style: { width: 160 },
      ref: 'updatedAt',
      title: 'Date de modification',
      ban: ['update'],
    },
    {
      style: { width: 140 },
      ref: 'collaborateur',
      title: 'Collaborateur',
      ban: ['update'],
    },
    {
      type: 'date',
      style: { width: 140 },
      ref: 'dateListeNoire',
      title: 'Date de liste noire',
      ban: ['update'],
    },

    ...listeNoireUserSchema.schemas,

    {
      style: { width: 200 },
      ref: 'dateAppelHeure',
      type: 'date',
      title: "Date de l'appel heure",
      ban: ['update'],
    },
    {
      style: { width: 140 },
      ref: 'laisseMessageLe',
      title: 'Laisse message le',
      type: 'date',
      ban: ['update'],
    },
    {
      style: { width: 200 },
      ref: 'annulerLe',
      title: 'Annuler le ….',
      type: 'date',
      ban: ['update'],
    },
    {
      style: { width: 140 },
      ref: 'renvoiRappel',
      title: 'Renvoi rappel',
      type: 'date',
      ban: ['update'],
    },
    {
      style: { width: 200 },
      ref: 'paiementPrevuLe',
      title: 'Paiement prevu le',
      type: 'date',
      ban: ['update'],
    },
    {
      style: { width: 350 },
      ref: 'diversInfos',
      title: 'Divers et infos / Annulation preciser la raison',
      type: 'pargraphe2',
      ban: ['update'],
    },
    {
      style: { width: 140 },
      ref: 'rappelLe',
      title: 'Rappel le :',
      type: 'date',
      ban: ['update'],
    },
  ],
};

export default listeNoireUserAdminSchema;

import { PageConfig } from '../type';

export const ristourneSchema: PageConfig = {
  config: {
    modelName: 'Ristournes',
    itemName: 'une ristourne',
    apiUrl: '/ristournes',
    options: [
      'create',
      'update',
      'updateMany',
      'delete',
      'filter',
      'order',
      'deleteMany',
    ],
  },
  schemas: [
    {
      style: { width: 100 },
      ref: 'ref',
      type: 'ref',
      search: true,
      form: { display: false },
    },
    {
      style: { width: 120 },
      ref: 'mandat/ref',
      type: 'ref',
      form: {
        select: {
          apiUrl: '/mandats',
          fieldName: 'ref',
          fieldResult: 'id',
          type: 'autocomplete',
        },
      },
    },
    {
      style: { width: 160 },
      ref: 'mandat/club/nom',
      type: 'ref',
      form: { display: false },
    },
    {
      style: { width: 200 },
      ref: 'article',
      search: true,
      form: { requier: true },
    },
    { style: { width: 140 }, ref: 'notes' },
    {
      style: { width: 140 },
      ref: 'montantRistourne',
      type: 'money',
      form: { requier: true },
    },
    { style: { width: 140 }, ref: 'destinataire' },
    { style: { width: 200 }, ref: 'datePaiementRistourne', type: 'date' },
  ],
};

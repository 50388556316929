import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectRoutes } from '../system/components/protectRoute';
const MainLayout = lazy(() => import('../layout/MainLayout'));

// APP ROUTES
import TablesAppRoutes from '../main/tables/TablesAppRoutes';
import SignAppRoutes from '../main/sign/SignAppRoutes';
import HomeAppRoutes from '../main/home/HomeAppRoutes';
import SettingsAppRoutes from '../main/settings/SettingsAppRoutes';
import MetierAppRoutes from '../main/metier/MetierAppRoutes';

const GestRoutes = [SignAppRoutes];
const AuthRoutes = [TablesAppRoutes, HomeAppRoutes, SettingsAppRoutes, MetierAppRoutes];
const AuthRoutesNoLayout = [null];

function RoutesConfig() {
  return (
    <>
      <Suspense>
        <Routes>
          {GestRoutes}
          <Route element={<ProtectRoutes />}>
            <Route element={<MainLayout />}>{AuthRoutes}</Route>
            {AuthRoutesNoLayout}
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default RoutesConfig;

import { PageConfig } from "@app/pages";
import AssignListeNoire from "./Custom/AssignListeNoire";
import ValidateListeNoire from "./Custom/ValidateListNoire";

type props = {
    pageConfig: PageConfig,
    selected: string[],
    onSuccess: () => void,
    open: boolean,
    onClose: () => void,
}

// List of pages how get some custom identifier
const identifierForCustomAction = [
    'listeNoireViewSchema',
    'listeNoireUserAdminSchema',
]

const UpdateManyCustom = ({pageConfig, selected, onSuccess, open, onClose }: props) => {
    return <>
        {pageConfig.config.indentifier === identifierForCustomAction[0] && (    
            <AssignListeNoire pageConfig={pageConfig} idToUpdate={selected.map(id => parseInt(id))} onSuccess={onSuccess} open={open} onClose={onClose} />
        )}
        {pageConfig.config.indentifier === identifierForCustomAction[1] && (    
            <ValidateListeNoire idToUpdate={selected.map(id => parseInt(id))} onSuccess={onSuccess} open={open} onClose={onClose} />
        )}
    </>
};

export default UpdateManyCustom;

import { PageConfig } from '../type';

export const ballonsDueToClubViewSchema: PageConfig = {
  config: {
    modelName: 'Ballons dû au club',
    itemName: 'un ballon du au club',
    apiUrl: '/views/ballonDueToClub',
    options: ['updateMany', 'delete'],
  },
  schemas: [
    {
      style: { width: 220 },
      ref: 'MandatID',
      title: 'Mandat Id',
      type: 'ref',
      search: true,
    },
    { style: { width: 220 }, ref: 'NomDuClub', search: true },
    { style: { width: 220 }, ref: 'MontantParrainagesPayes', type: 'money' },
    { style: { width: 220 }, ref: 'MontantParrainagesPayes75', type: 'money' },
    { style: { width: 220 }, ref: 'MontantBallonsDejaRecus', type: 'money' },
    {
      style: { width: 220 },
      ref: 'BallonsDuEnCHF',
      title: 'Ballons dû en CHF',
      type: 'money',
    },
  ],
};

import { Schema, PageConfig } from "@app/pages";
import { AppModal } from "@app/components/AppModal";
import AppDatePicker from "@app/components/AppDatePicker";
import { useEffect, useState } from "react";
import { deleteRequest, updateRequest } from '@app/api/generic/generic';
import { Select, Option, Switch, FormControl, FormLabel, Input, Alert } from "@mui/joy";
import { useAppSchema } from "@app/system/hooks/useAppSchema";
import AppLoading from "@app/components/AppLoading";
import { AppIcon } from "@app/components/AppIcon";

interface Props {
    open: boolean;
    onClose: () => void;
    selected: string[];
    pageConfig: PageConfig;
    onSuccess: () => void;
    archive: boolean;
}

type SelectedOptionItem = {
    ref: Schema['ref'],
    type: Schema['type'],
}
export const UpdateMany = ({open, onClose, selected, pageConfig, onSuccess, archive = false}: Props) => {

    const { getSchemaTitle, renderFilterIcon } = useAppSchema()
    const [selectedOption, setSelectedOption] = useState<SelectedOptionItem>({ref: '', type: undefined})
    const [selectedDate, setSelectedDate] = useState<Date>()
    const [selectedBool, setSelectedBool] = useState<Boolean>(false)
    const [value, setValue] = useState<String>('')
    const [numberValue, setNumberValue] = useState<number>()

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleGroupedActionSubmit = async () => {
        setIsLoading(true);
        // si archive est true, alors on fait une action d'archive
        if(archive === true){
            await actionArchive();
        // sinon on fait une action de modification
        }else{
            await actionModify();
        }
        setIsLoading(false);
        onClose();
        onSuccess();
    }

    const actionModify = async () => {
        let data = {}; 
        if(specialField.includes(selectedOption.ref)){
            if(selectedBool === true){
                data = {[dateNameLink[specialField.findIndex(item => item === selectedOption.ref)]]: selectedDate}
            }else[
                data = {[selectedOption.ref]: false}
            ]
        }else{
            data = {
                [selectedOption.ref]: 
                selectedOption.type === 'bool' ? 
                selectedBool : selectedOption.type === 'date' ? 
                selectedDate : selectedOption.type === 'date-full' ? 
                selectedDate : 
                numberTypeList.includes(selectedOption.type) ? 
                numberValue : value 
            }
        }

        const promises = selected.map((id: string) => {
            return updateRequest(pageConfig.config.apiUrl, id, data);  
        });
        await Promise.all(promises);
    } 

    const actionArchive = async () => {
        const promises = selected.map((id: string) => {
            return deleteRequest(pageConfig.config.apiUrl, id);  
        });
        await Promise.all(promises);
    
    }

    useEffect(() => {
        setSelectedDate(undefined)
        setSelectedBool(false)
    }, [selectedOption])

    const includeTypeList: Schema['type'][] = ['bool', 'date', 'date-full', 'ref', 'percent']
    const fieldTypeList: Schema['type'][] = ['ref']
    const numberTypeList: Schema['type'][] = ['percent']

    const isValideType = (type: Schema['type']) => {
        if( !type || includeTypeList.includes(type)){
            return true
        }
        return false 
    }

    // Is the correct type for simple string text field 
    const isValueType = (type: Schema['type']) => {
        if(!type || fieldTypeList.includes(type)){
            return true
        }
        return false
    }
    const specialField = ['annulation', 'storno', 'stornoReactive', 'facture/paiement']
    const dateNameLink = ['dateAnnulation', 'dateStorno', 'dataStornoReactive', 'facture/datePaiement']

    return (
        <>  
            <AppModal handleSubmit={handleGroupedActionSubmit} open={open} onClose={onClose} title="Modifications groupées" icon='PencilIcon'>
                
            {archive ? <div>
                <Alert startDecorator={<AppIcon icon='ShieldExclamationIcon' solid />} variant="soft" color="warning">Êtes-vous sûrs de vouloir archiver ces {selected.length} éléments ?</Alert>
            </div>
            :
            <div className="w-48 mx-8 my-4 space-y-4">
                    <FormControl size="sm">
                        <FormLabel>Champ à modifier</FormLabel>
                        <Select
                                size="sm"
                                placeholder="---" 
                                slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                value={selectedOption.ref}
                            >
                                <Option onClick={() => setSelectedOption({ref:'', type: undefined})} value=''>---</Option>
                                {pageConfig.schemas.map((schema: Schema, index) => (
                                    !schema.ban?.includes('updateMany') && isValideType(schema.type) && 
                                    <Option 
                                        onClick={() => setSelectedOption({ref: schema.ref, type: schema.type})} 
                                        key={index} 
                                        value={schema.ref}
                                    >
                                        {renderFilterIcon(schema.type)}
                                        {getSchemaTitle(schema)}
                                    </Option>
                                ))}
                        </Select>
                    </FormControl>
                    
                    <FormControl size="sm">
                    <FormLabel>Valeur</FormLabel>
                    {selectedOption.ref === '' ? 
                        <Input disabled size="sm" placeholder="---" />
                        :
                        <>
                            {selectedOption.type === 'date' &&
                                <AppDatePicker selected={selectedDate} setSelected={setSelectedDate}></AppDatePicker>
                            }
                            {selectedOption.type === 'date-full' &&
                                <AppDatePicker selected={selectedDate} setSelected={setSelectedDate}></AppDatePicker>
                            }
                            {selectedOption.type === 'bool' &&
                                <Switch checked={selectedBool === true ? true : false} onClick={() => setSelectedBool(!selectedBool)} className="mt-1" size="sm" variant="soft" />
                            }
                            {isValueType(selectedOption.type) && 
                                <Input onChange={(e) => setValue(e.target.value)} className="mt-1" size="sm"  />
                            }
                            {numberTypeList.includes(selectedOption.type) &&
                                <Input onChange={(e) => setNumberValue(Number(e.target.value))} className="mt-1" size="sm" type="number" />
                            }
                        </>
                    }
                    { specialField.includes(selectedOption.ref) &&
                        <div className='mt-4'>
                            <AppDatePicker disabled={selectedBool === true ? false : true} selected={selectedDate} setSelected={setSelectedDate}></AppDatePicker>
                        </div>
                    }
                    </FormControl>
                    {isLoading === true && <AppLoading/>}
            </div>}
            </AppModal>
        </>
    )
}

export default UpdateMany
import { Schema } from "@app/pages"
import * as React from 'react';
import { RenderValue } from "./RenderValue";
import { useAppSchema } from "@app/system/hooks/useAppSchema";
import RenderCustomValue from "./RenderCustomValue";
import { Sheet } from "@mui/joy";
interface props {
    schemas: Schema[],
    data: any,
    id: number,
    onSuccess: () => void,
}

export const RenderRow = ({ schemas, data, id, onSuccess }: props) => {
    const {getData} = useAppSchema()
    return (
        <>
            {schemas.map((schema, schemaIndex) => (
                schema.style?.isDisplayInTable !== false &&
                <React.Fragment key={schemaIndex}>
                    {schema.type === 'recursive' ?
                        <>
                            <RenderValue schema={schema} field={getData(data, schema.ref)} id={id} onSuccess={onSuccess}/>
                        </>
                        :
                       
                        <td 
                            className={schema?.style?.fixed ? 'sticky left-0 border-x-2 border-gray-500' : ''}
                            style={
                                { 
                                    zIndex: schema?.style?.fixed ? '10' : '', 
                                    width: schema?.style?.width || '240px',
                                    padding: '12px 6px' 
                                }
                            }
                        >
                                { schema.type !== 'custom' ?
                                    <RenderValue schema={schema} field={getData(data, schema.ref)} id={id} onSuccess={onSuccess}/>
                                    : 
                                    <RenderCustomValue data={data} schema={schema}/>
                                }
                        </td>
                        
                    }
                </React.Fragment>
            ))}
        </>
    )
}
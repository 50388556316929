import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getCleanDatas, getCleanHeader } from './dataExporter';
import { getAllRequest } from '@app/api/generic/generic';
import JSZip from 'jszip';
import { downloadFile } from './dataExporter';
import Logo from '@app/ressources/mv-logo.png';

export const exportPdf = (headers: any[], datas: any[], modelName: string) => {
  const doc = new jsPDF({
    orientation: 'landscape',
  });

  const text =
    'Export - ' + modelName + ' - ' + new Date().toLocaleDateString('en-GB');
  doc.setFontSize(8);
  doc.addImage(Logo, 'png', 260, 5, 20, 4); // adjust positioning and size as needed

  doc.text(text, 16, 14);
  autoTable(doc, {
    styles: { fontSize: 5 },
    startY: 25,
    horizontalPageBreak: true,
    theme: 'striped',
    head: [headers],
    body: datas,
  });
  doc.save(modelName + '.pdf');
};

interface tableList {
  tableName: string;
  headers: any[];
  datas: any;
}

const createPDFTable = (tableList: tableList[]) => {
  const doc = new jsPDF({
    orientation: 'landscape',
  });
  // add a logo to the firt page

  doc.setFontSize(8);

  doc.addImage(Logo, 'png', 260, 5, 20, 4); // adjust positioning and size as needed

  tableList.forEach((table, index) => {
    const cleanData = getCleanDatas(table.datas, table.headers, true);
    const cleanHeader = getCleanHeader(table.headers);
    let posY = (doc as any).lastAutoTable.finalY;
    if (posY === undefined) {
      posY = 10;
    }
    const text = table.tableName;
    doc.text(text, 16, posY + 8);
    autoTable(doc, {
      styles: { fontSize: 5 },
      startY: posY + 10,
      horizontalPageBreak: true,
      theme: 'striped',
      head: [cleanHeader],
      body: cleanData,
    });
  });
  return doc.output('arraybuffer');
};

export const exportParrainageCurrentMonth = async (
  headers: any[],
  filter: any,
) => {
  var zip: any = new JSZip();
  const date = filter.find(
    (element: any) =>
      element.filter_by === 'dateAccord' &&
      element.filter_type === 'select_month_metier',
  ).filter_value;
  const month =
    date.split('-')[0].length === 1
      ? '0' + date.split('-')[0]
      : date.split('-')[0];
  const year = date.split('-')[1];
  const fullDate = month + '/' + year;

  const allCollabParrainages: any = await getAllRequest(
    '/view-parrainages/currentMonthAllCollaborateurs',
    {
      page: 0,
      limit: 0,
      search: '',
      secondarySearch: '',
      sort_by: '',
      asc: true,
      filters: filter,
    },
  );

  allCollabParrainages.forEach((datas: any, index: number) => {
    const allData = [
      {
        headers: headers,
        datas: { tableData: datas.main.result, aggregate: datas.main.total },
        tableName: 'Export - ' + datas.userRef + ' - ' + fullDate,
      },
      {
        headers: headers,
        datas: {
          tableData: datas.annulation.result,
          aggregate: datas.annulation.total,
        },
        tableName: 'ANNULATIONS',
      },
      {
        headers: headers,
        datas: {
          tableData: datas.storno.result,
          aggregate: datas.storno.total,
        },
        tableName: 'STORNOS',
      },
      {
        headers: headers,
        datas: {
          tableData: datas.stornoReactive.result,
          aggregate: datas.stornoReactive.total,
        },
        tableName: 'STORNOS Réactivés',
      },
    ];
    const pdf = createPDFTable(allData);

    zip.file(datas.userRef + '.pdf', pdf);
  });

  const content = await zip.generateAsync({ type: 'blob' });
  downloadFile(content, 'Parrainages du mois ' + fullDate, 'zip', true);
  return true;
};

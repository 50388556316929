import { useEffect, useState } from 'react';
import { Box, Button, Select, Option } from '@mui/joy';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import { AppIcon } from '@app/components/AppIcon';
import { useAppNavigation } from '@app/system/hooks/useAppNavigation'

interface PaginationProps {
  totalCount: number;
  modelName: string;
  defaultPageLimit: number;
}

const Pagination = (props: PaginationProps) => {
  const { totalCount, modelName, defaultPageLimit } = props;
  const { setParams, getParams } = useAppNavigation()

  const page = parseInt(getParams('page') || '1')
  const limit = parseInt(getParams('limit') || defaultPageLimit.toString())

  const [displayPages, setDisplayPages] = useState<string[]>([]);
  const from = (page - 1) * limit + 1;
  let to = (page - 1) * limit + limit ?? 1;
  if (to > totalCount) to = totalCount;

  const maxPage = Math.ceil(totalCount / limit);

  useEffect(() => {
    let _displayPages: string[] = [];

    if (maxPage <= 7) {
      // if there are less than 7 pages, display all pages
      _displayPages = Array(maxPage)
        .fill('')
        .map((_, i) => (i + 1).toString());
    } else {
      // if there are more than 7 pages, display only 7 pages
      if (page <= 4) {
        // if current page is less than 4, display the first 5 pages, ..., and the last 2 pages
        _displayPages = [
          '1',
          '2',
          '3',
          '4',
          '5',
          '...',
          (maxPage - 1).toString(),
          maxPage.toString(),
        ];
      } else if (page >= maxPage - 3) {
        // if current page is more than maxPage - 3, display the first 2 pages, ... , and the last 5 pages
        _displayPages = [
          '1',
          '2',
          '...',
          (maxPage - 4).toString(),
          (maxPage - 3).toString(),
          (maxPage - 2).toString(),
          (maxPage - 1).toString(),
          maxPage.toString(),
        ];
      } else {
        // else if current page is between 4 and maxPage - 3, display the current page, the 2 pages before and after, the last page and ... in between
        _displayPages = [
          '1',
          '...',
          (page - 1).toString(),
          page.toString(),
          (page + 1).toString(),
          '...',
          maxPage.toString(),
        ];
      }
    }

    setDisplayPages(_displayPages);
  }, [page, limit, totalCount]);

  const handleChangeLimit = (
    event: React.SyntheticEvent | null,
    newValue: string | null,
  ) => {
    newValue && setParams('limit', newValue)

    // setLimit(Number(newValue))
  };

  return (
    <>


      {maxPage > 1 && (
        <Box
          className="justify-between w-full pt-2"
          sx={{

            gap: 1,
            [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >

          <Button
            size="sm"
            className='font-semibold'
            variant="outlined"
            color="neutral"
            startDecorator={<AppIcon icon="ChevronLeftIcon" />}
            onClick={() => setParams("page", (page - 1).toString())}
            disabled={page === 1}
          >
            Précédent
          </Button>
          
          <Box sx={{ flex: 1 }} />
          {displayPages.map((value, i) => {
            return (
              <IconButton
                key={'page-' + i}
                size="sm"

                variant={value === '...' ? 'plain' : value === page.toString() ? 'solid' : 'outlined'}
                color="neutral"
                disabled={value === '...'}
                onClick={() => setParams("page", value)}
              >
                {value}
              </IconButton>
            );
          })}
          <div className="ml-8 flex items-center justify-between ">
            <p className="text-xs ">
              {modelName} de {from} à {to} sur {totalCount}
            </p>
          </div>
          <Box sx={{ flex: 1 }} />
          
          <div className="flex items-center justify-between">
            <p className="text-xs opacity-50">{modelName} par page &nbsp;</p>
            <Select
              onChange={handleChangeLimit}
              className="scale-75"
              size="sm"
              value={limit.toString()}
            >
              <Option value="25">25</Option>
              <Option value="50">50</Option>
              <Option value="100">100</Option>
              <Option value="200">200</Option>
              <Option value="300">300</Option>
            </Select>
          </div>
          <Button
            onClick={() => setParams("page", (page + 1).toString())}
            disabled={page === maxPage}
            size="sm"
            variant="outlined"
            color="neutral"
            endDecorator={<AppIcon icon="ChevronRightIcon" />}
          >
            Suivant
          </Button>


        </Box>

      )}
    </>
  );
};

export default Pagination;
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { FilterQuery } from '@app/api/apiType';

type FilterItem = {
    key: 'filter_by' | 'filter_type' | 'filter_value',
    value: string
}

export const useAppFiltersUrl = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const getParams = (key: string): string => {
        return searchParams.get(key) || ''
    }

    const setParams = (key: string, value: string) => {
        searchParams.set(key, value)
        setSearchParams(searchParams);
    }

    const getFilters = (): FilterQuery[] => {
        const filters = getParams('filters')
        if(filters){
            return JSON.parse(filters)
        }
        return []
    }

    // on init un filtre dans l'url
    const initFilter = async () => {
        const filters = getParams('filters')
        if(filters){
            const filtersParse = JSON.parse(filters)
            filtersParse.push({ filter_by: '', filter_type: '', filter_value: ''})
            setParams('filters', JSON.stringify(filtersParse))
        }else{
            setParams('filters', JSON.stringify([{ filter_by: '', filter_type: '', filter_value: ''}]))
        }
    }

    // on cree et on init un filtre dans l'url
    const createFilter = (filter: FilterQuery, index: number) => {
        let filters = getParams('filters')
        if(!filters){
            initFilter()
        }

        const filtersParse = JSON.parse(getParams('filters'))
        if(filtersParse[index] === undefined){
            filtersParse.push(filter)
        }else{
            filtersParse[index] = filter
        }
        setParams('filters', JSON.stringify(filtersParse))
        setParams('page', '1')
    }

    // on update un filtre existant dans l'url
    const updateFilter = (key: FilterItem['key'], value: FilterItem['value'],  index: number) => {
        const filters = getParams('filters')
        if(filters){
            const filtersParse = JSON.parse(filters)
            if(filtersParse[index] === undefined){
                throw new Error("No filters found at this index");
            }else{
                if(filtersParse[index][key] === undefined){
                    throw new Error("No key " + key + " found for this filter");
                }else{
                    filtersParse[index][key] = value
                }
            }
            setParams('filters', JSON.stringify(filtersParse))
            setParams('page', '1')
        }else{
            throw new Error("No filters init in url");
        }
    }

    // on init, cree ou update un filtre existant ou non dans l'url
    const autoUpdateFilter = async (filter: FilterQuery) => {
        
        const filtersParse = getFilters()
        const findFilterIndex = filtersParse.findIndex((element: FilterQuery) => element.filter_by === filter.filter_by && element.filter_type === filter.filter_type )
        if(filter.filter_value === '' && findFilterIndex !== -1){
            deleteFilter(findFilterIndex)

        }else if(findFilterIndex === -1){
            createFilter(filter, findFilterIndex)
            
        }else { 
            filtersParse[findFilterIndex] = filter
            setParams('filters', JSON.stringify(filtersParse))
            setParams('page', '1')
        }
        
    }

    const deleteFilter = (index: number) => {
        const filters = getParams('filters')
        if(filters){
            const filtersParse = JSON.parse(filters)
            if(filtersParse[index] === undefined){
                // throw new Error("No filters found at this index");
            }else{
                filtersParse.splice(index, 1)
            }
            if(filtersParse.length === 0){
                setParams('filters', '')
            }else{
                setParams('filters', JSON.stringify(filtersParse))
            }
        }
    }

    const deleteAllFilters = () => {
        setParams('filters', '')
    }

    const getApiFilters = () => {
        const filters = getFilters()
        const apiFilters: FilterQuery[] = []
        filters.forEach(filter => {
            if(filter.filter_by && filter.filter_type && filter.filter_value){
                apiFilters.push(filter)
            }
        })
        return apiFilters
    }



    return { 
        getFilters,
        initFilter,
        createFilter,
        updateFilter, 
        autoUpdateFilter,
        deleteFilter,
        deleteAllFilters,
        getApiFilters,
    }
}



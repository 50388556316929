import axios from '@app/config/axios.config';
import { returnErrorMsg } from '@app/system/utils/basic';
import { exportExcelFile } from '@app/system/utils/dataExporter';

export type Parrainages = {
};

export type MigrateParrainages = {
    file: File;
};

var configXml = {
  headers: {'Content-Type': 'text/xml'}
};
const configJson = {
  headers: {'Content-Type': 'application/json'}
};

export type ModelTarget = 'parrainage' | 'commandeMateriel' | 'club' | 'collaborateur'

export const migrateXml = async (
  modelTarget: ModelTarget,
  file: File,
  onSuccess?: () => void,
  onError?: (err: string) => void
) => {

  let endUrl = ''

  // si on est en developement on ne migre pas toutes les données
  if (process.env.NODE_ENV === 'development') {
    const base = 0;
    const range = 1000;
    const end = base + range; 
    endUrl = '&start=' + base + '&end=' + end
  }
  
  await axios.post('migrations/xml/' + modelTarget + '?getReport=true&checkExisting=true' + endUrl, file, configXml).then((res) => {
    onSuccess && onSuccess()
  }).catch((err) => {
    onError && onError(returnErrorMsg(err))
  });
};

export type MigrateParrainagesType = {
  file?: File;
  data?: any;
  onSuccess?: () => void;
  onError?: (err: string) => void;
}

export const migrateParrainages = async (
  {file, data, onSuccess, onError}: MigrateParrainagesType
) => {

  const isXml = file ? true : false;
  if(!isXml && !data) {
    throw new Error('File or data required');
  }
  await axios.post('migrations/importParrainages' + (isXml ? '?isXml=true' : ''), isXml ? file : [data], isXml ? configXml : configJson).then((res) => {

    exportExcelFile(res.data.factureReport.header, {tableData: res.data.factureReport.tableData}, 'Factures import report')
    exportExcelFile(res.data.parrainageReport.header, {tableData: res.data.parrainageReport.tableData}, 'Parrainages import report')

    onSuccess && onSuccess()
  }).catch((err) => {
    onError && onError(returnErrorMsg(err))
  });
}

export const migrateCsv = async (
  file: File,
  onSuccess?: (resp: any) => void,
  onError?: (err: string) => void
) => {
  await axios.post('factures/csv', file, configXml).then((resp) => {
    onSuccess && onSuccess(resp.data)
  }).catch((err) => {
    onError && onError(returnErrorMsg(err))
  });;
};

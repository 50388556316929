import { StatusChip } from "./StatusChip"
import { useState } from "react";
import { OrderTable } from "./OrderTable";
import { Box } from "@mui/joy";
import { PageConfig } from "@app/pages";

interface Props {
    data: [][]
    pageConfig: PageConfig
    title: string
    amount: number
    onSuccess: () => any,
}

export const SubTableBtn = ({ data, pageConfig, title, amount, onSuccess }: Props) => {
    // const wrapperRef = useRef(null);
    // useOutsideAlerter(wrapperRef, () => setOpen(false));
    const [open, setOpen] = useState(false)

    return (
        <>
            <div className="relative" >

                <StatusChip
                    color={amount === 0 ? 'green' : 'blue'}
                    title={title}
                    selected={open}
                    icon={open ? 'ChevronDownIcon' : 'ChevronUpIcon'}
                    iconEnd
                    handleClick={() => setOpen(!open)}
                />

                {open &&
                    <Box
                        className='absolute z-99 shadow-md mt-2'
                        style={{ "boxShadow": "5px 5px 15px -1px rgba(0,0,0,0.3)" }}
                        sx={{
                            borderRadius: 'sm',
                            display: {
                                xs: 'none',
                                sm: 'flex',
                            },
                            flexWrap: 'wrap',
                            gap: 1.5,

                        }}
                    >
                        <OrderTable 
                            totalCount={0}
                            onSuccess={onSuccess}
                            pageConfig={pageConfig}
                            data={data}
                            isLoaded
                            isSub={true}
                        />
                    </Box>
                }

            </div>
        </>
    )
} 
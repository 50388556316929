import { Relation, PageConfig } from "@app/pages";
import { Autocomplete, Button, FormControl, FormLabel } from "@mui/joy";
import { updateRequest } from "@app/api/generic/generic";
import { useEffect, useState } from "react";
import axios from '@app/config/axios.config';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AppIcon } from "@app/components/AppIcon";


interface SubRelationFormProps {
    pageConfig: PageConfig;
    data: any;
    onSuccess: () => void;
}

const getTitle = (element: any, displayFieldOption: string[]): string => {
    return displayFieldOption.map((field) => element[field]).join(' ')
}

export const SubRelationForm = ({pageConfig, data, onSuccess }: SubRelationFormProps) => {

    return (
        <>
            {pageConfig.relations && pageConfig.relations.map((relation: Relation, index: number) => (
                relation.many ? 
                    <SubRelationMany key={index} relation={relation} data={data} parentUrl={pageConfig.config.apiUrl} onSuccess={onSuccess}/>
                :   
                    <SubRelationFormItem key={index} relation={relation} data={data} parentUrl={pageConfig.config.apiUrl} onSuccess={onSuccess}/>
            ))}
        </>
    )
}

const SubRelationMany = ({data, relation, parentUrl, onSuccess}:SubRelationFormItemProps) => {

    const intialModelId: optionItem[] = data[relation.getKeyInData]?.map((element: any) => ({id: element.id, title: getTitle(element, relation.displayFieldOption)})) || undefined
    
    type optionItem = {
        id: string;
        title: string;
    }
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 10,
        stringify: (option: optionItem) => option.title,
    });
    
    const [selectedModelId, setSelectedModelId] = useState<optionItem[]>(intialModelId)
    const [optionsList, setOptionsList] = useState<optionItem[]>([{id: 'null', title: 'chargement...'}] as optionItem[])

    useEffect(() => {
        axios.get(relation.getApiUrlOptions,{params: {
            limit: 10000
        }}).then((res) => {
            setOptionsList(res.data.result.map((element: any) => ({id: element.id, title: getTitle(element, relation.displayFieldOption)})))
        })
    }, [])

    const UpdateRelations = () => {
        updateRequest(parentUrl, data.id, {[relation.postKeyDb]: selectedModelId.map((element) => parseInt(element.id))}, () => {
            onSuccess()
        })
    }

    return (
        <div className="mx-2 flex space-x-2 my-4">
            <FormControl>
                <FormLabel>{relation.labelName}</FormLabel>
                <Autocomplete
                    multiple
                    options={optionsList}
                    value={selectedModelId}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, value) => setSelectedModelId(value)}
                    filterOptions={filterOptions}
                />
            </FormControl>
            <FormControl>
                <FormLabel>&nbsp;</FormLabel>
                <Button variant="soft" color="primary" onClick={() => UpdateRelations()} startDecorator={<AppIcon icon='ArrowPathIcon' />}> <div className="flex items-center space-x-4 whitespace-nowrap"> Mettre à jour</div></Button>
            </FormControl>
        </div>
    )
}

interface SubRelationFormItemProps {
    data: any;
    relation: Relation;
    parentUrl: string;
    onSuccess: () => void;
}   

const SubRelationFormItem = ({data, relation, parentUrl, onSuccess}:SubRelationFormItemProps) => {
 
    const element = data[relation.getKeyInData]
    let intialModelId: optionItem
    if(element){
        intialModelId = {id: element?.id || 'null', title: getTitle(element, relation.displayFieldOption) || ''}
    }else{
        intialModelId = {id: 'null', title: ''}
    }

    type optionItem = {
        id: string;
        title: string;
    }
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 10,
        stringify: (option: optionItem) => option.title,
    });
    
    const [selectedModelId, setSelectedModelId] = useState<optionItem>(intialModelId)
    const [optionsList, setOptionsList] = useState<optionItem[]>([{id: '', title: 'chargement...'}] as optionItem[])
    
    useEffect(() => {
        axios.get(relation.getApiUrlOptions,{params: {
            limit: 10000
        }}).then((res) => {
            setOptionsList(res.data.result.map((element: any) => ({id: element.id, title: getTitle(element, relation.displayFieldOption)})))
        })
    }, [])

    const UpdateVersion = () => {
        updateRequest(parentUrl, data.id, {[relation.postKeyDb]: parseInt(selectedModelId.id)}, () => {
            onSuccess()
        })
    }

    return (
        <div className="mx-2 flex space-x-2 my-4">
           
            <FormControl>
                <FormLabel>{relation.labelName}</FormLabel>
                <Autocomplete
                    options={optionsList}
                    value={selectedModelId}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, value) => setSelectedModelId(value || { id: '', title: '' })}
                    filterOptions={filterOptions}
                />
            </FormControl>

            <FormControl>
                <FormLabel>&nbsp;</FormLabel>
                <Button variant="soft" color="primary"  onClick={() => UpdateVersion()} startDecorator={<AppIcon icon='ArrowPathIcon' />}>Mettre à jour</Button>
            </FormControl>
        </div>
    );
};

export default SubRelationForm;




import { getTablesParams } from "@app/api/apiType";
import { getAllRequest } from "@app/api/generic/generic";
import { Select, Option } from "@mui/joy";
import { useState, useEffect } from "react";
import { FormSelect } from "@app/pages";
import {Autocomplete, FormControl} from "@mui/joy";
import Close from '@mui/icons-material/Close';

interface props{
    apiUrl: string;
    keyRelation: string;
    keyResult: string;
    type: FormSelect['type'],
    onChange: (arg1: string) => void;
    value: string | number;
    filters?: any
    disabled?: boolean;
}

export const RequestField = ({apiUrl, keyRelation, keyResult, onChange, value, type, filters, disabled}: props) => {
    const [allValues, setAllValues] = useState<any>([]);
    const [lastFilter, setLastFilter] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      const param: getTablesParams = {
        page: 0,
        limit: 10000,
        search: "",
        secondarySearch: "",
        sort_by: "",
        asc: false,
        filters: filters ? filters : '',
      };
      const result = (await getAllRequest(apiUrl, param)).result;
      setAllValues(result);
    }
    

    if(filters && lastFilter !== JSON.stringify(filters)){
      setLastFilter(JSON.stringify(filters))
      fetchData();
    }else if(!filters){
      fetchData();
    }
    
  }, [apiUrl, filters]);


    return (
      <>
        { type === 'select' && 
          <Select value={String(value)} disabled={disabled} >
              <Option value="" onClick={() => onChange('')}>---</Option>
              {allValues && allValues.length >= 1 && allValues.sort(
                (a: any, b: any) => a[keyRelation] !== null && a[keyRelation].localeCompare(b[keyRelation])
              ).map((option: any, index: number) => (
                  <Option key={index} onClick={() => onChange(option[keyResult])} value={String(option[keyResult])}>{option[keyRelation]}</Option>
              )) }
          </Select>
        }
        
        { type === 'autocomplete' &&
            <Autocomplete
              disabled={disabled}
              options={allValues}
              getOptionLabel={(option) => option[keyRelation]}
              value={allValues && allValues.find((option: any) => option[keyResult] === value) || null}
              onChange={(_, newValue) => {
                if (newValue) {
                  onChange(newValue[keyResult]);
                } else {
                  onChange('');
                }
              }}
            />
        }
      </>
     )
}

export default RequestField
import { PageConfig } from '../type';

export const parrainagesCurrentMonthSchema: PageConfig = {
  config: {
    modelName: 'parrainages du mois actuel',
    itemName: 'un parrainage',
    apiUrl: '/view-parrainages/currentMonth',
    options: ['filter', 'order'],
    secondarySearch: 'Numero Facture',
    redirectTo: '/parrainages',
    // defaultOrder: { ['facture/numeroFacture']: 'asc' },
  },
  schemas: [
    {
      style: { width: 200 },
      ref: 'personneMorale/raisonSociale',
      title: 'Raison Sociale',
      search: true,
    },
    { style: { width: 140 }, ref: 'prenom', ban: ['order', 'filter'] },
    { style: { width: 140 }, ref: 'nom', ban: ['order', 'filter'] },
    { style: { width: 140 }, ref: 'prenom2', ban: ['order', 'filter'] },
    { style: { width: 140 }, ref: 'nom2', ban: ['order', 'filter'] },
    {
      style: { width: 200 },
      ref: 'personneMorale/adresse/ligne1',
      title: 'Adresse',
    },
    {
      style: { width: 140 },
      ref: 'personneMorale/adresse/casePostal',
      title: 'Case Postale',
    },
    { style: { width: 140 }, ref: 'personneMorale/adresse/NPA', title: 'Npa' },
    {
      style: { width: 140 },
      ref: 'personneMorale/adresse/region',
      title: 'Localite',
    },
    { style: { width: 140 }, ref: 'dateAccord', type: 'date' },
    {
      style: { width: 140 },
      ref: 'facture/numeroFacture',
      title: 'Numero Facture',
    },
    {
      style: { width: 140 },
      ref: 'montantPromesse',
      type: 'money',
      title: 'Chf promesse',
      search: true,
    },
    { style: { width: 140 }, ref: 'mandat/ref', title: 'Id Club' },
    {
      style: { width: 140 },
      ref: 'collaborateur/ref',
      title: 'Id Collaborateur',
    },
    // {
    //   style: { width: 180 },
    //   ref: 'collaborateur/statut',
    //   title: 'Statut Collaborateur',
    //   type: 'select',
    //   form: {requier: true},
    //   valueMap: [
    //     { value: 'interne', color: 'blue', icon: 'ArrowLeftOnRectangleIcon' },
    //     { value: 'externe', color: 'red', icon: 'ArrowRightOnRectangleIcon' },
    //   ],
    // },
  ],
};
